<template>
  <div class="row">
    <div class="col-md-12">
      <ValidationObserver v-slot="{ invalid }">
        <card>
          <h5 slot="header" class="title">{{$t('editMonitor.cardTitle')}}</h5>
          <div class="row">
            <div v-if="isAdmin" class="col-md-3">
              <base-input label="User" placeholder="Bms">
                    <select v-model="selectedUser"  id="inputState" class="form-control">
                      <option :value="{}" disabled selected hidden>{{$t('commons.userSelectPlaceholder')}}</option>
                      <option
                        v-for="(user) in users"
                        :value="user"
                        :key="user.email"
                      >{{user.username}}</option>
                    </select>             
                  </base-input>
            </div>

            <ValidationProvider :rules="{  required: true , monitorNotInArray : [monitorNameList()] }" ref="monitorNameValidationProvider" v-slot="{ errors }" slim>
              <div class="col-md-3">
                <div>
                  <label class="control-label">{{$t('editMonitor.monitorName')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="monitorName">                        
                    </div>       
                  </div>
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>   
            </ValidationProvider>
            <!--
            <div class="col-md-3">
              <base-input label="Monitor name"
                        v-model="model.monitorName">
              </base-input>
            </div>
            -->

            <ValidationProvider rules="required|email" name="email" v-slot="{ errors }" slim>
              <div class="col-md-3">
                <div>
                  <label class="control-label">{{$t('editMonitor.email')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="email">                        
                    </div>       
                  </div>
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>
            <!--
            <div class="col-md-3">
              <base-input label="Email"
                        v-model="email">
              </base-input>
            </div>
            -->
          </div>
          <div class="row">
          </div>
          <div class="row">
            <ValidationProvider rules="required|double|between:1.50,3" v-slot="{ validate, errors }" slim>
              <div class="col-md-4">
                <div>
                  <label class="control-label">{{$t('editMonitor.cellsUndervoltageThreshold')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-3" style="margin-right: -15px;">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="model.elemVmin"
                        @change="validate">                        
                    </div>       
                  </div>
                  <div class="col-md-9">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control custom-range input-error' : 'form-control custom-range'"                 
                        type="range"
                        min="1.50"
                        max="3"
                        step=".01"
                        v-model="model.elemVmin">
                    </div>
                  </div>        
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>

            <ValidationProvider rules="required|double|between:1.50,5" v-slot="{ validate, errors }" slim>
              <div class="col-md-4">
                <div>
                  <label class="control-label">{{$t('editMonitor.minCellsChargingVoltage')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-3" style="margin-right: -15px;">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="model.elemVmcv"
                        @change="validate">                        
                    </div>       
                  </div>
                  <div class="col-md-9">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control custom-range input-error' : 'form-control custom-range'"                 
                        type="range"
                        min="1.50"
                        max="5"
                        step=".01"
                        v-model="model.elemVmcv">
                    </div>
                  </div>        
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>

            <ValidationProvider rules="required|integer|between:0,480" v-slot="{ validate, errors }" slim>
              <div class="col-md-4">
                <div>
                  <label class="control-label">{{$t('editMonitor.minChargingTime')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-3" style="margin-right: -15px;">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="model.minDuration"
                        @change="validate">                        
                    </div>       
                  </div>
                  <div class="col-md-9">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control custom-range input-error' : 'form-control custom-range'"                 
                        type="range"
                        min="0"
                        max="480"
                        step="1"
                        v-model="model.minDuration">
                    </div>
                  </div>        
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>
            <!--
            <div class="col-md-3">
              <base-input label="Minimum charging time (min)"
                        v-model="model.minDuration">
              </base-input>
            </div>
            -->
          </div>
          <div class="row">

            <ValidationProvider rules="required|integer|between:-40,20" v-slot="{ validate, errors }" slim>
              <div class="col-md-4">
                <div>
                  <label class="control-label">{{$t('editMonitor.minTemp')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-3" style="margin-right: -15px;">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="model.Tmin"
                        @change="validate">                        
                    </div>       
                  </div>
                  <div class="col-md-9">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control custom-range input-error' : 'form-control custom-range'"                 
                        type="range"
                        min="-40"
                        max="20"
                        step="1"
                        v-model="model.Tmin">
                    </div>
                  </div>        
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>
            <!--
            <div class="col-md-3">
              <base-input label="Minimum temperature (°C)"
                        v-model="model.Tmin">
              </base-input>
            </div>
            -->

            <ValidationProvider rules="required|integer|between:20,200" v-slot="{ validate, errors }" slim>
              <div class="col-md-4">
                <div>
                  <label class="control-label">{{$t('editMonitor.maxTemp')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-3" style="margin-right: -15px;">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="model.Tmax"
                        @change="validate">                        
                    </div>       
                  </div>
                  <div class="col-md-9">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control custom-range input-error' : 'form-control custom-range'"                 
                        type="range"
                        min="20"
                        max="200"
                        step="1"
                        v-model="model.Tmax">
                    </div>
                  </div>        
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>
            <!--
            <div class="col-md-3">
              <base-input label="Maximum temperature (°C)"
                        v-model="model.Tmax">
              </base-input>
            </div>
            -->

            <ValidationProvider rules="required|integer|between:1,20" v-slot="{ validate, errors }" slim>
              <div class="col-md-2">
                <div>
                  <label class="control-label">{{$t('editMonitor.numCycles')}}</label>
                </div>
                <div class="row">
                  <div class="col-md-6" style="margin-right: -15px;">
                    <div class="form-group" style="margin: 0px;">
                      <input
                        v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                        v-model="model.numCycles"
                        @change="validate">                        
                    </div>       
                  </div>     
                </div>
                <label class="input-error">{{errors[0]}}</label>
              </div>     
            </ValidationProvider>
            <!--
            <div class="col-md-3">
              <base-input label="Number of cycles"
                        v-model="model.numCycles">
              </base-input>
            </div>
            -->
          </div>

          <div class="row">
            <ValidationProvider rules="listNotEmpty" ref="bmsListValidationProvider" name="currentUserBmsList" v-slot="{ errors }" slim>
              <div class="col-md-12">
                <label for="tags">{{$t('editMonitor.associatedBms')}}</label>
                <b-form-tags
                  :class="errors[0] ? 'input-error' : ''"
                  id="tags-"
                  v-model="currentUserBmsList"

                  add-on-change
                  no-outer-focus
                >
                  <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2" style="margin: 0px !important;">
                      <li v-for="tag in tags" :key="tag" class="list-inline-item">
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="info"
                        >{{ tag }}</b-form-tag>
                      </li>
                    </ul>

                    <base-input class="list-inline d-inline-block col-md-2" placeholder="Bms" style="margin-bottom: 0px;">
                      <select v-bind="inputAttrs" v-on="inputHandlers" id="inputState" class="form-control">
                        <option value="" disabled selected hidden>{{$t('commons.bmsSelectPlaceholder')}}</option>
                        <option
                          v-for="(bms) in availableBms"
                          :value="bms"
                          :key="bms"
                        >{{bms}}</option>
                      </select>             
                    </base-input>
                  </template>
                </b-form-tags>   
                <label class="input-error">{{errors[0]}}</label> 
              </div>
            </ValidationProvider>
          </div>
          <div class="button-row">
            <div class="button-container">
              <base-button slot="footer" type="primary-nogradient" @click="saveMonitor()" fill :disabled="invalid">{{$t('editMonitor.saveButton')}}</base-button>
            </div>
            <div class="button-container">
              <base-button slot="footer" type="danger-nogradient" @click="cancel()" fill>{{$t('editMonitor.backButton')}}</base-button>
            </div>
          </div>
        </card>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, integer, double, min_value, max_value, between, email } from 'vee-validate/dist/rules';

  export default {
    data() {
      return {
        model: {
          elemVmin: 1.65,
          elemVmcv: 2.40,
          minDuration: 240,
          Tmin: -10,
          Tmax: 60,
          numCycles: 1,
          bmsList: []
        },
        selectedUser: {},       
        email: '',
        monitorName: ''
      }
    },
    mounted() {
      if(!this.isAdmin) {
        this.selectedUser = this.loggedUser
      }
      this.localizeValidationErrorMessages()
    },
    computed: {
      ...mapState([
        'users',
        'bmss',
        'monitors',
        'loggedUser',
        'isAdmin'
      ]),
      currentUserBmsList: {
        get: function() {
          return 'bmsList' in this.model ? this.model.bmsList : []
        },
        set: function(newValue) {
          this.model.bmsList = newValue
          //this.$refs.bmsListValidationProvider.validate(newValue);
        }      
      },
      availableBms() {
        let bmsList = ('bmsList' in this.model) ? this.model.bmsList : []
        return this.bmss
          .filter(bms => bms.owner == this.selectedUser.email)
          .map(bms => bms.bms_id)
          .filter(bms => !bmsList.includes(bms))
      },
      locale() {
        return this.$i18n.locale
      },
    },
    watch: {
      selectedUser: function (newSelectedUser, oldSelectedUser) {
        this.email = newSelectedUser.email || ''
      },
      locale : function () {
        this.localizeValidationErrorMessages()
      }
    },
    methods: {
      monitorNameList() {
        return this.monitors
          .filter(monitor => monitor.user == this.selectedUser.email)
          .map(monitor => monitor.monitorName)
      },
      localizeValidationErrorMessages() {
        extend('required', {
          ...required,
          message: this.$t('validationErrorMessages.required')
        });
        extend('integer', {
          ...integer,
          message: this.$t('validationErrorMessages.integer')
        });
        extend('double', {
          ...double,
          message: this.$t('validationErrorMessages.double')
        });
        extend('between', {
          ...between,
          params: ['min', 'max'],
          message: this.$t('validationErrorMessages.between')
        });
        extend('listNotEmpty', {
          ...required,
          message: this.$t('validationErrorMessages.listNotEmpty')
        });
        extend('email', {
          ...email,
          message: this.$t('validationErrorMessages.email')
        });
        extend('monitorNotInArray', {
          validate(value ,{ monitorList }) {
            return !Array.isArray(monitorList) || (!monitorList.includes(value.trim()))
          },
          params: ['monitorList'],
          message: this.$t('validationErrorMessages.monitorNotInArray')
        });
      },
      async saveMonitor() {
        // TODO put in data and read localStorage on mounted()
        let selectedMonitor = localStorage.getItem("selectedMonitor")

        if(!selectedMonitor || selectedMonitor == '{}') {
          console.log("Creating new monitor")    
          this.model.user = this.selectedUser.email
          this.model.username = this.selectedUser.username
          this.model.email = this.email
          this.model.monitorName = this.monitorName
          console.log(this.model)
          
          this.axios.post('https://4wq3fnef63.execute-api.eu-central-1.amazonaws.com/default/listMonitors', this.model).then((response) => {    
            console.log("CREATED MONITOR")     
            console.log(response)
            this.$router.push('alerts-panel')
          }).catch(error => {
            console.log("ERROR CREATING MONITOR")
            //console.log(error)
            console.log(error.response.data)
            this.$router.push('alerts-panel')
          });   
           
        } else {
          /*
          console.log("Updating existing user")
          console.log(this.model)
          this.axios.post('https://5e80bb0mtk.execute-api.eu-central-1.amazonaws.com/default/listBmsUsers', this.model).then((response) => {    
            console.log("UPDATED USER")     
            console.log(response)
            this.$router.push('users-panel')
          }).catch(error => {
            console.log("ERROR UPDATING USER")
            //console.log(error)
            console.log(error.response.data)
            this.$router.push('users-panel')
          });
          */
        }

      },
      cancel() {
        this.$router.push('alerts-panel')
      }
    }
  }
</script>
<style>

li.badge, span.badge{
  margin: 5px;
  background-color: #3792cb;
  
}

span.b-form-tag-content{
  font-size: 1rem ;
  font-weight: 500;
  color: white;
  margin: 10px;
}

button.b-form-tags-button{
  height: 50px;
  background-color: #3792cb;
  background-image: none;
}

div.list-inline {
  margin-left: 5px;
  padding: 0;
}

</style>
