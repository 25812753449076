<template>
  <div>
    <card v-if="isAdmin || isLvl1User">
      <form @submit.prevent>
        <div class="form-row">
          <base-input class="col-md-3" placeholder="Bms">
              <select v-model="selectedUser" id="inputState" class="form-control">
                <option :value="{}" disabled>{{$t('commons.userSelectPlaceholder')}}</option>  
                <option value="*">{{$t('alertsPanel.selectPlaceholder')}}</option>            
                <option
                  v-for="(user) in users"
                  :value="user"
                  :key="user.email"
                >{{user.username}}</option>
              </select>             
            </base-input>
        </div>
      </form>
    </card>
    <div class="row">
      <div class="col-12">
        <card title="Monitors">
          <div class="table-responsive">
            <b-table 
              striped 
              hover 
              sticky-header
              id="monitors-table"
              :fields="monitorsFields"
              :items="getMonitors"
              selectable
              :select-mode="'single'"
              @row-selected="onMonitorSelected"
              :per-page="monitorsPerPage"
              :current-page="monitorsCurrentPage">
            <template #cell(customCellBmsList)="data">
              {{ mapBmsIdListToAliases(data.item.bmsList).join(', ') }}
            </template>
            </b-table>
            <b-pagination
              v-model="monitorsCurrentPage"
              :total-monitorsRows="monitorsRows"
              :per-page="monitorsPerPage"
              aria-controls="monitors-table"
            ></b-pagination>
          </div>

          <div slot="footer" class="button-row">
            <div class="button-container">
              <base-button type="primary-nogradient" @click="newMonitor()" fill>{{$t('alertsPanel.newMonitorButton')}}</base-button>
            </div>
            <div class="button-container">
              <base-button v-if="selectedMonitor.monitorId"  type="danger-nogradient" @click="deleteMonitor()" fill>{{$t('alertsPanel.deleteMonitorButton')}} {{selectedMonitor.monitorName}}</base-button>        
            </div>
          </div>
        
        </card>
      </div>
    </div>
    <div v-if="selectedMonitor.monitorId" class="row">
      <div class="col-12">
        <card :title="$t('alertsPanel.alertsCardTitle') + ' ' + selectedMonitor.monitorName">
          <div class="button-row">

              <base-input class="button-container bmsSelect" placeholder="Bms">
                <select v-model="selectedBms" @change="onBmsSelected($event)" class="form-control">
                  <option :value="{}" disabled>{{$t('commons.bmsSelectPlaceholder')}}</option>                 
                  <option
                    v-for="(bms) in selectedMonitorBMSList"
                    :value="bms.bms_id"
                    :key="bms.bms_id"
                  >{{bms.alias || bms.bms_id}}</option>
                </select>   
                      
              </base-input> 

              <div v-if="areAlertsLoaded">
                <div v-if="areAlertsDismissed" class="button-container">
                  <base-button slot="footer" type="primary-nogradient" @click="setAlertNotification(true)" fill>{{$t('alertsPanel.enableNotificationButton')}}</base-button> 
                </div>
                <div v-else class="button-container">
                  <base-button slot="footer" type="danger-nogradient" @click="setAlertNotification(false)" fill>{{$t('alertsPanel.disableNotificationButton')}}</base-button>        
              </div>
              </div>      

          </div>
          <div v-if="areAlertsLoaded" class="table-responsive">
            <b-table
              striped 
              hover 
              sticky-header
              id="alerts-table"
              :fields="alertsFields"
              :items="alerts"
              selectable
              :select-mode="'single'"
              :per-page="alertsPerPage"
              :current-page="alertsCurrentPage">
              <template #cell(shortCharge)="data">
                <div>
                  <i v-if="data.value" class="fas fa-triangle-exclamation" style="color: red"></i>
                </div>
                {{ data.value ? "" : "-" }}
              </template>
              <template #cell(elemVmin)="data">
                {{data.value ? data.value.toFixed(2) : "" }}
              </template>
              <template #cell(undervoltage)="data">
                <div>
                  <i v-if="data.value" class="fas fa-triangle-exclamation" style="color: red"></i>
                </div>
                {{ data.value ? "" : "-" }}
              </template>
              <template #cell(elemVmax)="data">
                {{data.value ? data.value.toFixed(2) : "" }}
              </template>
              <template #cell(lowVoltageCharge)="data">
                <div>
                  <i v-if="data.value" class="fas fa-triangle-exclamation" style="color: red"></i>
                </div>
                {{ data.value ? "" : "-" }}
              </template>
              <template #cell(undertemperature)="data">
                <div>
                  <i v-if="data.value" class="fas fa-triangle-exclamation" style="color: red"></i>
                </div>
                {{ data.value ? "" : "-" }}
              </template>
              <template #cell(overtemperature)="data">
                <div>
                  <i v-if="data.value" class="fas fa-triangle-exclamation" style="color: red"></i>
                </div>
                {{ data.value ? "" : "-" }}
              </template>
              <template #cell(refillError)="data">
                <div>
                  <i v-if="data.value == 1" class="fas fa-thin fa-droplet-slash" style="color: red"></i>
                  <i v-else class="fas fa-thin fa-droplet" style="color: green"></i>
                </div>
              </template>
              <template #cell(customCellNotified)="data">
                <div class="popup" @click="displayNotificationTooltip(data.index)">
                  <i v-if="data.item.notified" class="fas fa-envelope"  style="color: green"></i>
                  <span class="popuptext" :class="{ show: selectedTooltip == data.index }" :id="'notificationTooltip'+data.index">{{(new Date(data.item.creationDate)).toUTCString()}}</span>
                </div>
                {{ data.item.notified ? "" : "-" }}
              </template>
              <template #cell(dismissed)="data">
                <div>
                  <i v-if="data.value" class="fas fa-bell-slash" style="color: red"></i>
                  <i v-else class="fas fa-bell" style="color: green"></i>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="alertsCurrentPage"
              :total-rows="alertsRows"
              :per-page="alertsPerPage"
              aria-controls="alerts-table"
            ></b-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Card from '@/components/Cards/Card.vue';
import { batteryTechEnum } from '@/config/env'

export default {
    components: {
        Card
  },
  data() {
    return {
      monitorsPerPage: 15,
      monitorsCurrentPage: 1,
      selectedUser: {},
      selectedMonitor: {},
      selectedBms: {},
      alertsPerPage: 15,
      alertsCurrentPage: 1,
      batteryTechEnum: batteryTechEnum,
      alerts: [],
      areAlertsLoaded: false,
      areAlertsDismissed: false,
      monitorsFields: [ ],
      alertsFields: [ ],
      selectedTooltip: null,
    };
  },
  watch: {
    locale : function (newLocale, oldLocale) {
      this.tableFields = this.computeTableFields(); 
    }
  },
  computed: {
    getMonitors() {
      if(this.selectedUser == '*')
        return this.monitors
      else
        return this.monitors.filter(monitor => monitor.user == this.selectedUser.email)
    },
    monitorsRows() {
      return this.monitors.length
    },
    alertsRows() {
      return this.alerts.length
    },
    selectedMonitorBMSList() {
      return this.bmss.filter(bms => this.selectedMonitor.bmsList.includes(bms.bms_id)) || []
    },
    locale() {
      return this.$i18n.locale
    },
    ...mapState([
      'logged',
      'isAdmin',
      'isLvl1User',
      'users',
      'monitors',
      'whiteTheme',
      'calendarColor',
      'bmss'
    ])
  },
  mounted() {
    if(!this.isAdmin) this.selectedUser = this.users[0]
    this.$store.dispatch('updateMonitors')
    this.computeTableFields()
  },
  methods: {
    displayNotificationTooltip(index) {
      if(this.selectedTooltip == index)
        this.selectedTooltip = null
      else
        this.selectedTooltip = index
    },
    onMonitorSelected(items) {
      var monitor = items[0]
      if(monitor) {
        this.areAlertsLoaded = false
        this.areAlertsDismissed = false
        this.selectedMonitor = monitor
        this.selectedBms = {}
        //this.fetchAlerts(monitor.monitorId)
      }
    },
    onBmsSelected(event) {
      if(this.selectedBms)
        this.fetchAlerts(this.selectedMonitor.monitorId, this.selectedBms)
    },
    newMonitor() {
      localStorage.setItem("selectedMonitor", JSON.stringify({}))
      this.$router.push('edit-alert')
    },
    fetchAlerts(monitorId, bmsId) {
      console.log(`Fetching alerts of monitor ${monitorId} and bms ${bmsId}`);
      this.areAlertsLoaded = false
      this.areAlertsDismissed = false
      this.axios.get(`https://zay1o5bewa.execute-api.eu-central-1.amazonaws.com/default/listAlerts?monitorId=${monitorId}&bmsId=${bmsId}`).then(result => {
        console.log(result)
        var fetchedAlerts = result.data
        var lastAlertPointer = fetchedAlerts.find(a => a.cycleNum == -1)
        if(lastAlertPointer && lastAlertPointer.dismissed)
          this.areAlertsDismissed = true
        this.alerts = fetchedAlerts.filter(a => a.cycleNum != -1)
        this.areAlertsLoaded = true
      }).catch(error => {
        console.log("Error fetching alerts")
        console.log(error)
      })

    },
    setAlertNotification(isActive) {
      this.areAlertsDismissed = !isActive
      console.log("Enablind alert notifications")
      var body = {
        monitorId: this.selectedMonitor.monitorId,
        bmsId: this.selectedBms,
        enabled: !this.areAlertsDismissed
      }
      console.log(body)
      this.axios.post(`https://rp2364bivb.execute-api.eu-central-1.amazonaws.com/default/enableAlertNotifications`, body).then(result => {
        console.log(result)
      }).catch(error => {
        console.log(`Error enablind alert notifications`)
        console.log(error)
      })
    },
    deleteMonitor() {
      var monitorId = this.selectedMonitor.monitorId
      console.log("Deleting monitor " + monitorId)
      let config = {
        headers: {
          "access-control-allow-methods": "OPTIONS,POST,GET,DELETE",
        }
      }
      this.axios.delete(`https://4wq3fnef63.execute-api.eu-central-1.amazonaws.com/default/listMonitors?monitorId=${monitorId}`, config).then(result => {
        console.log(`Monitor ${monitorId} deleted`)
        this.selectedMonitor = {}
        this.$store.dispatch('updateMonitors')
      }).catch(error => {
        console.log(`Error deleting monitor ${monitorId}`)
        console.log(error)
      })
    },
    computeTableFields() {
      this.monitorsFields = [
        {
          key: 'monitorName',
          label: this.$i18n.t('alertsPanel.monitorName')},
        {
          key: 'email',
          label: this.$i18n.t('alertsPanel.email')},
        {
          key: 'customCellBmsList',
          label: this.$i18n.t('alertsPanel.monitoredBms')},
        {
          key: 'elemVmin',
          label: this.$i18n.t('alertsPanel.cellsUndervoltageThreshold')},
        {
          key: 'elemVmcv',
          label: this.$i18n.t('alertsPanel.cellsMinChargingVoltage')},
        {
          key: 'Tmin',
          label: this.$i18n.t('alertsPanel.minTemp')},
        {
          key: 'Tmax',
          label: this.$i18n.t('alertsPanel.maxTemp')},
        {
          key: 'minDuration',
          label: this.$i18n.t('editMonitor.minChargingTime')},
        {
          key: 'numCycles',
          label: this.$i18n.t('alertsPanel.numCycles')},
        ],
      this.alertsFields = [
        {
          key: 'cycleNum',
          label: this.$i18n.t('alertsPanel.cycleNo')},
        {
          key: 'cycleDuration',
          label: this.$i18n.t('alertsPanel.duration')},
        {
          key: 'shortCharge',
          label: this.$i18n.t('alertsPanel.shortCharge')},
        {
          key: 'elemVmin',
          label: this.$i18n.t('alertsPanel.cellVmin')},
        {
          key: 'undervoltage',
          label: this.$i18n.t('alertsPanel.undervoltage')},
        {
          key: 'elemVmax',
          label: this.$i18n.t('alertsPanel.cellVmax')},
        {
          key: 'lowVoltageCharge',
          label: this.$i18n.t('alertsPanel.lowCharge')},
        {
          key: 'Tmin',
          label: this.$i18n.t('alertsPanel.minTemp')},
        {
          key: 'undertemperature',
          label: this.$i18n.t('alertsPanel.undertemperature')},
        {
          key: 'Tmax',
          label: this.$i18n.t('alertsPanel.maxTemp')},
        {
          key: 'overtemperature',
          label: this.$i18n.t('alertsPanel.overheating')},
        {
          key: 'refillError',
          label: this.$i18n.t('alertsPanel.refill')},
        {
          key: 'customCellNotified',
          label: this.$i18n.t('alertsPanel.notified')},
        {
          key: 'dismissed',
          label: this.$i18n.t('alertsPanel.notifications')},
        ]

        if(this.isAdmin) this.monitorsFields.unshift({
          key: 'username',
          label: this.$i18n.t('alertsPanel.username')},
        );
    },
    mapBmsIdListToAliases(bmsIdList) {
        return this.bmss
        .filter(fullBms => bmsIdList.includes(fullBms.bms_id))
        .map(fullBms => fullBms.alias ? fullBms.alias : fullBms.bms_id);
    },
  }
};
</script>
<style>

.bmsSelect {
	margin-top: auto;
	margin-bottom: auto;
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class when clicking on the popup container (hide and show the popup) */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

</style>