<template>
  <div>
    <card v-if="isAdmin">
      <form @submit.prevent>
        <div class="form-row">
          <base-input class="col-md-3" placeholder="User">
              <select v-model="selectedUser" @change="onUserSelect()" id="inputState" class="form-control">
                <option :value="{}" disabled>Select a user</option>
                <option
                  v-for="(user) in users"
                  :value="user"
                  :key="user.email"
                >{{user.username}}</option>
              </select>             
            </base-input>
        </div>
      </form>
    </card>
    <card>
      <div>
        <verte v-model="pickedColor" display="widget" :showHistory="null" :model="verteModel"></verte>
      </div>
      <div>
        <span class="btn btn-primary btn-simple btn-file">
          <span class="fileinput-new">Upload logo</span>
          <input type="hidden" value="" name="">
          <input accept="image/*" type="file" @change=uploadImage name="logoInput" aria-invalid="false" class="valid">
        </span>
        <base-button slot="footer" type="primary-nogradient" @click="saveCustomization()" fill>{{$t('editUser.saveButton')}}</base-button>
        <base-button slot="footer" type="primary-nogradient" @click="back()" fill>{{$t('editUser.backButton')}}</base-button>
      </div>
    </card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
           
export default {
  data() {
    return {
      selectedUser: {},
      previewImage: null,
      pickedColor: "#000000",
      verteModel: "hex"
    };
  },
  computed: {
    ...mapState([
      'logged',
      'loggedUser',
      'loggedUserEmail',
      'isAdmin',
      'users',
      'mainColor'
    ])
  },
  watch: {
    pickedColor: function (val) {
      this.$store.commit('setTempColor', val)
    },
  },
  methods: {
    onUserSelect() {
      this.fetchUserCustomization();
    },
    uploadImage(e){
      console.log("Image loaded")
      console.log(e)
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
        console.log("Image read")
        console.log(e)
        this.previewImage = e.target.result;
        console.log(this.previewImage);
        this.$store.commit('setTempLogo', this.previewImage)
      };
    },
    fetchUserCustomization() {
      this.axios.get(`https://rjl94x88s3.execute-api.eu-central-1.amazonaws.com/default/userCustomization?id=${this.selectedUser.email}`).then((response) => {    
        console.log("FETCHED CUSTOMIZATION")     
        console.log(response)
        if(response.data) {
          if(response.data.image)
            this.$store.commit('setTempLogo', response.data.image)
          else
            this.$store.commit('setTempLogo', '')
          if(response.data.color)
            this.$store.commit('setTempColor', response.data.color)
          else
            this.$store.commit('setTempColor', "#3792cb")
        }     
      }).catch(error => {
        console.log("ERROR FETCHED CUSTOMIZATION")
        //console.log(error)
        console.log(error.response.data)
      }); 
    },
    saveCustomization() {
      // If an admin saves the customization for another user, the color returns normal on exiting the page 
      if(this.selectedUser.email == this.loggedUserEmail)
        this.$store.commit('saveCustomization')

      this.axios.post(`https://rjl94x88s3.execute-api.eu-central-1.amazonaws.com/default/userCustomization?id=${this.selectedUser.email}`, {image: this.previewImage, color: this.pickedColor}).then((response) => {    
        console.log("SAVED CUSTOMIZATION")     
        console.log(response)
      }).catch(error => {
        console.log("ERROR CUSTOMIZING USER")
        //console.log(error)
        console.log(error.response.data)
        this.$router.push('users-panel')
      }); 
      
      this.$router.push('recharge-room')
    },
    back() {
      this.$router.push('recharge-room')
    }
  },
  mounted() {
    this.selectedUser = this.loggedUser
    this.pickedColor = this.mainColor
  }
};
</script>
<style>

.verte {
  justify-content: left;
  margin-bottom: 10px;
}

.btn-file > input {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	font-size: 23px;
	cursor: pointer;
	filter: alpha(opacity=0);
	opacity: 0;
	direction: ltr;
}

</style>
