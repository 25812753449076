<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card :title="$t('userPanel.tableTitle')">
          <div class="table-responsive">
            <b-table 
              striped 
              hover 
              sticky-header
              id="my-table"
              :fields="fields"
              :items="users"
              selectable
              :select-mode="'single'"
              :per-page="perPage"
              :current-page="currentPage">
              <template #cell(edit)="data">
                <button class="btn btn-link" @click="rowSelected(data.item)" >
                  <i class='tim-icons icon-pencil'></i>
                </button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <base-button slot="footer" type="primary-nogradient" @click="newUser()" fill>{{$t('userPanel.newUserButton')}}</base-button>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Card from '@/components/Cards/Card.vue';

export default {
    components: {
        Card
  },
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      fields: [],
      items: [],
      selected: [],
      nextToken: ''
    };
  },
  watch: {
    locale : function (newLocale, oldLocale) {
      this.tableFields = this.computeTableFields(); 
    }
  },
  mounted() {
    this.$store.dispatch('updateUsers')
    this.$store.dispatch('updateBMSs')
    this.fields = this.computeTableFields()
  },
  computed: {
    rows() {
      return this.users.length
    },
    ...mapState([
      'logged',
      'users',
      'bmss',
      'whiteTheme',
      'calendarColor'
    ]),
    locale() {
      return this.$i18n.locale
    },
  },
  methods: {
    ...mapMutations([
      'setUsers',
      'setBMSs'
    ]),
    rowSelected(user) {
      if(user) {
        console.log("Selected user")
        console.log(user)
        localStorage.setItem("selectedUser", JSON.stringify(user))
        this.$router.push('edit-user')
      }
    },
    newUser() {
      localStorage.setItem("selectedUser", JSON.stringify({}))
      this.$router.push('edit-user')
    },
    computeTableFields() {
      let fields = [
        {
          key: 'company',
          label: this.$i18n.t('userPanel.company')}
        ,{
          key: 'username',
          label: this.$i18n.t('userPanel.username')}
        ,{
          key: 'email',
          label: this.$i18n.t('userPanel.email')}
        ,{
          key: 'bucket',
          label: this.$i18n.t('userPanel.bucket')}
        ,{
          key: 'edit',
          label: this.$i18n.t('userPanel.edit')},
      ]
      return fields
    }
  }
};
</script>
