<template>
  <div>

    <card>
      <form @submit.prevent>
        <div v-if="isAdmin || isLvl1User" class="form-row">

          <base-input v-if="isAdmin || isLvl1User" class="col-md-3" placeholder="Bms">
            <select v-model="selectedUser" id="inputState" class="form-control">
              <option :value="{}" disabled>{{$t('commons.userSelectPlaceholder')}}</option>
              <option
                v-for="(user) in users"
                :value="user"
                :key="user.email"
              >{{user.username}}</option>
            </select>             
          </base-input>

          <base-input class="col-md-2" placeholder="Bms">
            <select v-model="selectedBMS" id="inputState" class="form-control">
              <option :value="{}" disabled>{{$t('commons.bmsSelectPlaceholder')}}</option>
              <option
                v-for="(bms) in getBMSs"
                :value="bms.bms_id"
                :key="bms.bms_id"
              >{{bms.alias || bms.bms_id}}</option>
            </select>
          </base-input>

          <div class="col-md-2">
            <vc-date-picker
              v-model="range.start"
              mode="dateTime"
              :color="calendarColor"
              :is-dark="!whiteTheme"
              locale="en-GB"
              is24hr
              :max-date="new Date()"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <date-input
                  addon-left-icon="tim-icons icon-calendar-60"
                  placeholder="from"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>          
          </div>

          <div class="col-md-2">
            <vc-date-picker
              v-model="range.end"
              mode="dateTime"
              :color="calendarColor"
              :is-dark="!whiteTheme"
              locale="en-GB"
              is24hr
              :min-date="range.start"
              :max-date="new Date()"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <date-input
                  addon-left-icon="tim-icons icon-calendar-60"
                  placeholder="to"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>
          </div>

          <div class="form-group col-md-3">
            <base-button id="btnFetch" :disabled="!canQuery" @click="loadData" type="primary-nogradient">{{$t('commons.applyTimeRangeButton')}}</base-button>
          </div>
        </div>

        <div v-else class="form-row">

          <base-input class="col-md-2" placeholder="Bms">
            <select v-model="selectedBMS" id="inputState" class="form-control">
              <option :value="{}" disabled>{{$t('commons.bmsSelectPlaceholder')}}</option>
              <option
                v-for="(bms) in getBMSs"
                :value="bms.bms_id"
                :key="bms.bms_id"
              >{{bms.alias || bms.bms_id}}</option>
            </select>
          </base-input>

          <div class="col-md-2">
            <vc-date-picker
              v-model="range.start"
              mode="dateTime"
              :color="calendarColor"
              :is-dark="!whiteTheme"
              locale="en-GB"
              is24hr
              :max-date="new Date()"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <date-input
                  addon-left-icon="tim-icons icon-calendar-60"
                  placeholder="from"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>          
          </div>

          <div class="col-md-2">
            <vc-date-picker
              v-model="range.end"
              mode="dateTime"
              :color="calendarColor"
              :is-dark="!whiteTheme"
              locale="en-GB"
              is24hr
              :min-date="range.start"
              :max-date="new Date()"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <date-input
                  addon-left-icon="tim-icons icon-calendar-60"
                  placeholder="to"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </vc-date-picker>
          </div>

          <div class="form-group col-md-3">
            <base-button id="btnFetch" :disabled="!canQuery" @click="loadData" type="primary-nogradient">{{$t('commons.applyTimeRangeButton')}}</base-button>
          </div>
        </div>

      </form>
    </card>

    <div v-if="items.length > 0" class="row">
      <div class="col-12">
        
        <card >
          <h2 class="card-title">{{$t('chargeTable.tableTitle')}} {{currentBmsAlias}}</h2>
          <div class="table-responsive">
            <b-table 
              striped 
              hover 
              sticky-header
              id="my-table"
              :fields="tableFields"
              :items="items"
              :per-page="perPage"
              :current-page="currentPage">
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </card>
      </div>
    </div>
    <div v-else-if="this.queryLaunched" class="row">
      <div class="col-12">
        <card >
          <div>
          <center>{{$t('chargeTable.noData')}}</center>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Card from '@/components/Cards/Card.vue';
import {InfluxDB, FluxTableMetaData} from '@influxdata/influxdb-client'
import {url, org} from '@/config/env'

export default {
    components: {
        Card
  },
  data() {
    return {
      selectedUser: {},
      selectedBMS: {},
      loadedBMS: '',
      perPage: 15,
      currentPage: 1,
      tableFields: [
        {
          key: 'Time',
          label: this.$i18n.t('chargeTable.time')},
        {
          key: 'Current',
          label: this.$i18n.t('commons.current')+' (A)'},
        {
          key: 'Voltage',
          label: this.$i18n.t('commons.voltage')+' (V)'},
        {
          key: 'Charge',
          label: this.$i18n.t('commons.charge')+' (%)'},
        {
          key: 'Temperature',
          label: this.$i18n.t('commons.temperature')+' (°C)'},
      ],
      items: [],
      range: {
        start: new Date((new Date((new Date).setHours(0))).setMinutes(0)),
        end: new Date((new Date((new Date).setHours(23))).setMinutes(59)),
      },
      queryLaunched: false
    };
  },
  watch: {
    locale : function (newLocale, oldLocale) {
      this.tableFields = this.computeTableFields(); 
    }
  },
  computed: {
    ...mapState([
      'isAdmin',
      'isLvl1User',
      'logged',
      'whiteTheme',
      'calendarColor',
      'users',
      'bmss'
    ]),
    locale() {
      return this.$i18n.locale
    },
    getBMSs () {
      return this.bmss.filter(bms => bms.owner == this.selectedUser.email)
    },
    rows() {
      return this.items.length
    },
    canQuery() {
      return this.selectedBMS != '' && Object.keys(this.selectedBMS).length !== 0 &&
             this.selectedUser != '' && Object.keys(this.selectedUser).length !== 0 &&
             this.range.start != '' && 
             this.range.end != '' &&
             !this.dateEquals(this.range.start, this.range.end)
    },
    currentBmsAlias() {
      const loadedBms = this.loadedBMS;
      if(loadedBms && this.bmss) {
        var bms = this.bmss.find(bms => bms.bms_id == loadedBms)
        if(bms && bms.alias)
          return bms.alias
      }
      return 'bms ' + loadedBms
    }
  },
  mounted() {
    if(!this.isAdmin) this.selectedUser = this.users[0]
  },
  methods: {
    dateEquals(a, b) {
      // https://stackoverflow.com/questions/4587060/determining-date-equality-in-javascript
      return (a >= b && a <= b)
    },
    getToken() {
      return this.selectedUser.token || '';
    },
    getBucket() {
      return this.selectedUser.bucket || '';
    },
    buildQuery() {
      return `from(bucket: "${this.getBucket()}") 
              |> range(start: ${this.range.start.toISOString()}, stop: ${this.range.end.toISOString()})
              |> filter(fn: (r) => r._measurement == "tl")
              |> filter(fn: (r) => r.bm == "${this.selectedBMS}")
              |> filter(fn: (r) => r._field == "cu" or r._field == "vo" or r._field == "t1" or r._field == "ch")
              |> group()
              |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
              |> sort(columns: ["_time"])`
    },
    loadData() {
      var outerScope = this
      this.items = []
      this.currentPage = 1

      const dataQuery = this.buildQuery()
      const queryApi = new InfluxDB({url, token: this.getToken()}).getQueryApi(org)

      console.log('Querying influx for charge data.')
      console.log(dataQuery)
      queryApi.queryRows(dataQuery, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          var datum = {}
          var date = new Date(o._time)
          datum.Time = date.toLocaleString()
          datum.Current = o.cu.toFixed(1)
          datum.Voltage = o.vo.toFixed(1)
          datum.Temperature = o.t1.toFixed(1)
          datum.Charge = o.ch.toFixed(1)
          outerScope.items.push(datum)
        },
        error(error) {
          console.error(error)
          console.log('CHARGE DATA FETCH ERROR')
        },
        complete() {           
        },
      })
      this.loadedBMS = this.selectedBMS
      this.queryLaunched = true;
    }
  }
};
</script>
<style src="@/assets/css/input-bar.css"/>
