<template>
  <div>
    <card v-if="isAdmin || isLvl1User">
      <form @submit.prevent>
        <div class="form-row">
          <base-input class="col-md-3" placeholder="User">
              <select v-model="selectedUser" @change="onUserSelect()" id="inputState" class="form-control">
                <option :value="{}" disabled>{{ $t('commons.userSelectPlaceholder')}}</option>
                <option
                  v-for="(user) in users"
                  :value="user"
                  :key="user.email"
                >{{user.username}}</option>
              </select>             
            </base-input>
        </div>
      </form>
    </card>
    <div class="row"> 
      <div v-for="(cbData, index) in bmsData" :key="index" class="col-lg-6 col-md-6">
        <!-- Add a function in card sass like the one for the button, use a selector like card-border-$primary where $primary can be any of the ones defined in config -->
        <div v-bind:class="cbData | computeBMSCardCssClass" >
            <div class="card-body text-center">

              <div class="row" style="margin-bottom: 15px">

                <div class="col-6 align-items-center justify-content-center">               
                  <div class="text-center" style="margin-right: 15px">
                    <p class="card-category">Bms</p>
                    <h1 class="card-text">{{cbData.bms ? (cbData.alias || cbData.bms) : "-"}}</h1>
                  </div>                
                </div>

                <div class="col-6">
                  <div class="numbers">
                      <p class="card-category">{{$t('rechargeRoom.workingTime')}}</p>
                      <h1 class="card-text">{{formatMinutes(cbData.forkDuration)}}</h1>
                  </div>
                </div>

              </div>

              <div class="row">

                <div class="col-6 align-items-center d-flex justify-content-center">               
                  <div class="text-center" style="margin-right: 15px">
                    <p class="card-category">{{$t('rechargeRoom.lastSeen')}}</p>
                    <h1 class="card-text">{{cbData | computeLastSeen}}</h1>
                  </div>                
                </div>

                <div class="col-6">
                  <div class="numbers">
                      <p class="card-category">{{$t('rechargeRoom.batteryStatus')}}</p>
                      <h1 class="card-text">
                        <svg-icon v-if="(cbData.current && cbData.current < -2)" type="mdi" :path="forkliftIconPath" style="height: 42px; width: auto;"></svg-icon>
                        <i v-else-if="(cbData.current && cbData.current > 2)" class="fas fa-regular fa-charging-station"></i>
                        <i v-else-if="(typeof cbData.current !== 'undefined')" class="fas fa-solid fa-square-parking"></i>
                        {{cbData | computeBMSstatus}}
                        <i v-if="(cbData.bms && cbData.bmsError) || (cbData.cbError && !cbData.bms)" class="fas fa-droplet-slash" style="color: red;"></i>
                      </h1>
                      
                  </div>
                </div>

              </div>

            </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
const iconsContext = require.context('@/assets/icons/', true, /\.svg$/);
import {InfluxDB, FluxTableMetaData} from '@influxdata/influxdb-client'
import {url, token, org, presenceLookbackTime} from '@/config/env'
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiForklift } from '@mdi/js'
let queryApi = {}

const fluxQuery = `from(bucket: "test") 
                    |> range(start: -${presenceLookbackTime})
                    |> filter(fn: (r) => r["_measurement"] == "tl")
                    |> filter(fn: (r) => r["_field"] == "IP")
                    |> group(columns: ["origin"]) 
                    |> top(n:1, columns: ["_time"])
                    |> yield()`
                
export default {
  components: {
    SvgIcon
  },
  data() {
    return {
      batteryIconSrc: iconsContext('./batteryAlt.svg'),
      chargerIconSrc: iconsContext('./chargerAlt.svg'),
      selectedUser: {},
      cbsList: [],
      // A map would be ideal but maps are not currently supported by v-for https://github.com/vuejs/vue/issues/6644
      cbsData: [],
      // New fields to replace cbs
      bmsList:[],
      bmsData: [],
      forkliftIconPath: mdiForklift
    };
  },
  computed: {
    ...mapState([
      'logged',
      'loggedUserEmail',
      'isAdmin',
      'isLvl1User',
      'whiteTheme',
      'users',
      'bmss'
    ])
  },
  watch: {
    whiteTheme(newValue, oldValue) {
      this.toggleImages()
    },
    users(newValue, oldValue) {
      this.loadUser()
    },
    bmss(newValue, oldValue) {
      this.loadUser()
    }
  },
  filters: {
    computeBMSstatus(cbData) {
      //console.log("Computing bms status")
      //console.log(cbData)
      if(cbData.bms) {
        if(!cbData.charge) return "-"
        var status = cbData.charge + "%"
        if(cbData.bmsError) {
          /*
          var errCode = cbData.bmsError >> 7
          if(errCode == 1) {
            status += " Refill"
          }
          */
          if(cbData.bmsError)
            status += " Refill"
        }
        return status
      }
     /*   
      else if (cbData.cbError) {
        const errCode = cbData.cbError
        if(errCode == 1)
          return "CAN error"
        else if(errCode == 2)
          return "AUX error"
        else if(errCode == 3)
          return "CAN & AUX error"
        else if(errCode == 4)
          return "PWR error"
        else if(errCode == 5)
          return "PWR, CAN error"
        else if(errCode == 6)
          return "PWR, AUX error"
        else
          return "Error"
      }  
      */
      else
        return "-"
    },
    computeLastSeen(cbData) {
      if(!cbData.lastSeen) return '-'
      var newDate = new Date(cbData.lastSeen);
      //newDate.setTime(cbData.lastSeen * 1000);
      //let dateString = newDate.toUTCString();
      let dateString = newDate.toLocaleString();

      return dateString
    },
    computeBMSCardCssClass(cbData) {
      if(!cbData.bms || !cbData.charge)
        return ['card', 'card-stats', 'disconnected']
      if(cbData.charge < 80)
        return ['card', 'card-stats', 'not-charged']
      if(cbData.charge < 100)
        return ['card', 'card-stats', 'half-charged']
      else
        return ['card', 'card-stats', 'charged']
    }
  },
  methods: {
    loadUser() {
      if(this.selectedUser && this.users && this.users.length > 0 && !this.isAdmin) {
        this.selectedUser = this.users[0]
        this.onUserSelect();
      }
    },
    onUserSelect() {
      //console.log('User selected ', this.selectedUser);
      this.bmsList = this.selectedUser.bmsList || [];
      this.queryApi = new InfluxDB({url, token: this.getToken()}).getQueryApi(org)
      //console.log('InfluxDB client instance recreated with token ', this.getToken())
      //console.log(this.queryApi)
      //this.getBmsStatuses()
      this.getBmsData()
    },
    getBucket() {
      return this.selectedUser.bucket || '';
    },
    getToken() {
      return this.selectedUser.token || '';
    },
    toggleImages() {
      if(this.whiteTheme) {
        this.batteryIconSrc = iconsContext('./battery.svg');
        this.chargerIconSrc = iconsContext('./charger.svg');
      } else{
        this.batteryIconSrc = iconsContext('./batteryAlt.svg');
        this.chargerIconSrc = iconsContext('./chargerAlt.svg');
      }
    },
    formatRemainingChargeTime(minutesCharged, fullChargeInMinutes) {
      var remainingTime = fullChargeInMinutes - minutesCharged
      if(remainingTime < 0) remainingTime = 0
      return this.formatMinutes(remainingTime)
    },
    formatMinutes(minutes) {
      if(!minutes || minutes < 0) minutes = 0
      return Math.trunc(minutes/60) + 'h '  + (minutes % 60) + 'm'
    },
    computeChargeTime(cbData) {
      if(!cbData.bms) return "-"
      if(!cbData.charge) return "-"
      if(cbData.charge && cbData.charge == 100) return this.formatMinutes(0)
      if(!cbData.minutesRecharged) return "-"
      return this.formatRemainingChargeTime(cbData.minutesRecharged, cbData.hoursToRecharge*60)
    },
    getBmsStatuses() {
      console.log("Fetch bms statuses")
      
      if(!this.selectedUser) {
        return;
      }
      var outerScope = this 
      var promises = []

      if(this.bmsList) {
        this.bmsList.forEach(e => {
          //console.log(`Telemetry for cb: ${e.cbs}`)
          promises.push(outerScope.getBmsStatusPromise(e))       
        })

        Promise.allSettled(promises).then((results) => {
          const resolved = results.filter(r => r.status === "fulfilled");
          const values = resolved.map(r => r.value)
          //values.sort( (a, b) => a.cbs.localeCompare(b.cbs));
          outerScope.bmsData = values
          console.log(outerScope.bmsData)
        });
      }
      
    },
    getBmsStatusPromise(bmsId) {
      const vueScope = this;
      return new Promise((resolve, reject) => {
        const outerScope = {resolve, reject}
        const bmsStatusQuery = `from(bucket: "${vueScope.getBucket()}")
                                |> range(start: -10d)
                                |> filter(fn: (r) => r["_measurement"] == "tl")
                                |> filter(fn: (r) => r.bm == "${bmsId}")
                                |> group(columns: ["_field"])
                                |> top(n:1, columns: ["_time"])`;
        //console.log("Query")
        console.log(bmsStatusQuery)
        let bmsDataElement = this.bmss.find(bms => bms.bms_id == bmsId)
        bmsDataElement.bms = bmsDataElement.bms_id

        //console.log("Query Api")
        //console.log(vueScope.queryApi)
        vueScope.queryApi.queryRows(bmsStatusQuery, {
          next(row, tableMeta) {
            const o = tableMeta.toObject(row)
            if(o._field == "ch") bmsDataElement.charge = o._value
            if(o._field == "ct") bmsDataElement.chargeTime = o._value
            if(o._field == "e1") bmsDataElement.bmsError = o._value
            if(o._time) bmsDataElement.lastSeen = o._time
          },
          error(error) {
            console.log(`${bmsId} DATA FETCH ERROR`)
            console.error(error)
            outerScope.reject(new Error(error))
          },
          complete() {
            //console.log(`${bmsId} DATA FETCH SUCCESS`)
            //console.log(`Telemetry for cb: ${cbsListElement.cbs}`)
            console.log(`${bmsId} DATA FETCH SUCCESS`)
            console.log(bmsDataElement)
            outerScope.resolve(bmsDataElement)
          },
        })
      });
    },
    getBmsData() {
      if(!this.selectedUser || !this.bmsList || !this.bmss || this.bmsList.length === 0 || this.bmss.length === 0) {
        this.bmsData = {}
        return;
      }
      var outerScope = this 
      var query = this.buildQuery(this.bmsList)
      var bmsData = {}
      this.bmsList.forEach(bmsId => {
        var obj = this.bmss.find(bms => bms.bms_id == bmsId)
        obj.bms = bmsId
        bmsData[bmsId] = obj
      })

      this.queryApi.queryRows(query, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          var resultFields = o.result.split('_')
          var table = resultFields[0]
          var bmsId = resultFields[1]
          if(table == 'totalForkDuration') {
            bmsData[bmsId].forkDuration = o.forkDuration
          } else {
            if(o._field == "ch") bmsData[bmsId].charge = o._value
            if(o._field == "ct") bmsData[bmsId].chargeTime = o._value
            if(o._field == "cu") bmsData[bmsId].current = o._value
            if(o._field == "e1") bmsData[bmsId].bmsError = o._value
            if(o._time) bmsData[bmsId].lastSeen = o._time
          }
        },
        error(error) {
          console.log(`DATA FETCH ERROR`)
          console.error(error)
        },
        complete() {
          console.log(`DATA FETCH SUCCESS`)
          outerScope.bmsData = bmsData
        },
      })
    },
    buildSingleBmsQuery(bucket, bmsId) {
      const bmsStatusQuery = `statusData_${bmsId} = from(bucket: "${bucket}")
          |> range(start: -10d)
          |> filter(fn: (r) => r["_measurement"] == "tl")
          |> filter(fn: (r) => r.bm == "${bmsId}")
          |> group(columns: ["_field"])
          |> top(n:1, columns: ["_time"])
          |> yield(name: "statusData_${bmsId}")
      
        cyclesData_${bmsId} = from(bucket: "${bucket}")
          |> range(start: -10y)
          |> filter(fn: (r) => r["_measurement"] == "tl")
          |> filter(fn: (r) => r.bm == "${bmsId}")
          |> filter(fn: (r) => r["_field"] == "ft")
          |> filter(fn: (r) => exists r.nu)
          |> group(columns: ["bm", "nu"])

        minForkTime_${bmsId} = cyclesData_${bmsId}
          |> min()
        maxForkTime_${bmsId} = cyclesData_${bmsId}
          |> max()

        forkDurations_${bmsId} = join(tables: {minFt: minForkTime_${bmsId}, maxFt: maxForkTime_${bmsId}}, on: ["_start", "_stop", "bm", "nu", "_field", "_measurement"], method: "inner")
          |> map(fn: (r) => ({ r with forkDuration: int(v: r._value_maxFt) - int(v: r._value_minFt) }))
          |> group()

        totalForkDuration_${bmsId} = forkDurations_${bmsId}
          |> sum(column: "forkDuration")
          |> yield(name: "totalForkDuration_${bmsId}")`;
      return bmsStatusQuery;
    },
    buildQuery(bmsIdList) {
      return bmsIdList.map(bmsId => this.buildSingleBmsQuery(this.getBucket(), bmsId)).join('\n');
    },
  },
  mounted() {
    this.loadUser()
    this.toggleImages();
    // Refresh every 10 minutes
    var handler = setInterval(function () {
      //this.getBmsStatuses()
      this.getBmsData()
    }.bind(this), 10*60*1000)   
    this.$store.commit('addTimerHandler', handler)
  }
};
</script>
<style>

.charged {
  border-left: 75px solid #2dce89
}

.half-charged {
  border-left: 75px solid #fc8c42 
}

.not-charged {
  border-left: 75px solid #f5365c 
}

.disconnected {
  border-left: 75px solid #035afc
}

.category, .card-category {
	text-transform: none;
}

</style>
