<template>

<div>
  
  <card>

    <form @submit.prevent>
      <div class="form-row">

        <base-input v-if="isAdmin || isLvl1User" class="col-md-3" placeholder="Bms">
          <select v-model="selectedUser" id="inputState" class="form-control">
            <option :value="{}" disabled>{{$t('commons.userSelectPlaceholder')}}</option>
            <option
              v-for="(user) in getUsers"
              :value="user"
              :key="user.email"
            >{{user.username}}</option>
          </select>             
        </base-input>

        <base-input class="col-md-2" placeholder="Bms">
          <select v-model="selectedBMS" id="inputState" class="form-control">
            <option :value="{}" disabled>{{$t('commons.bmsSelectPlaceholder')}}</option>
            <option
              v-for="(bms) in getBMSs"
              :value="bms.bms_id"
              :key="bms.bms_id"
            >{{bms.alias || bms.bms_id}}</option>
          </select>             
        </base-input>

        <div class="col-md-2">
          <vc-date-picker
            v-model="range.start"
            mode="dateTime"
            :color="calendarColor"
            :is-dark="!whiteTheme"
            locale="en-GB"
            is24hr
            :max-date="new Date()"
            :popover="{ visibility: 'click' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <date-input
                addon-left-icon="tim-icons icon-calendar-60"
                placeholder="from"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </vc-date-picker>
        
        </div>
        <div class="col-md-2">

          <vc-date-picker
            v-model="range.end"
            mode="dateTime"
            :color="calendarColor"
            :is-dark="!whiteTheme"
            locale="en-GB"
            is24hr
            :min-date="range.start"
            :max-date="new Date()"
            :popover="{ visibility: 'click' }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <date-input
                addon-left-icon="tim-icons icon-calendar-60"
                placeholder="to"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </vc-date-picker>
        </div>
        
        <div class="form-group col-md-3" style="display: flex;">
          <base-button id="btnFetch" :disabled="!canQuery" @click="loadData" type="primary-nogradient">{{$t('commons.applyTimeRangeButton')}}</base-button>
          <base-button id="btnExtra" @click="toggleExtraInput" type="primary-nogradient">{{this.extraInputVisible ? "-" : "+"}}</base-button>
        </div>

      </div>
      <ValidationObserver ref="validationObserver" v-slot="{ invalid }">
      <div v-if="extraInputVisible" class="form-row" style="margin-top: 10px;">
        <ValidationProvider rules="required|integer" v-slot="{ validate, errors }" slim>
          <div class="col-md-2">
            <div>
              <label class="control-label">{{$t('chargeGraph.minVoltage')}}</label>
            </div>
              <div class="form-group" style="margin: 0px;">
                <input
                  v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                  v-model.number="minVoltage"
                  @change="validate">                        
              </div>       
            <label class="input-error">{{errors[0]}}</label>
          </div>     
        </ValidationProvider>
        <ValidationProvider rules="required|integer" v-slot="{ validate, errors }" slim>
          <div class="col-md-2">
            <div>
              <label class="control-label">{{$t('chargeGraph.maxVoltage')}}</label>
            </div>
              <div class="form-group" style="margin: 0px;">
                <input
                  v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                  v-model.number="maxVoltage"
                  @change="validate">                        
              </div>       
            <label class="input-error">{{errors[0]}}</label>
          </div>     
        </ValidationProvider>
        <ValidationProvider rules="required|integer" v-slot="{ validate, errors }" slim>
          <div class="col-md-2">
            <div>
              <label class="control-label">{{$t('chargeGraph.minCurrent')}}</label>
            </div>
              <div class="form-group" style="margin: 0px;">
                <input
                  v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                  v-model.number="minCurrent"
                  @change="validate">                        
              </div>       
            <label class="input-error">{{errors[0]}}</label>
          </div>     
        </ValidationProvider>
        <ValidationProvider rules="required|integer" v-slot="{ validate, errors }" slim>
          <div class="col-md-2">
            <div>
              <label class="control-label">{{$t('chargeGraph.maxCurrent')}}</label>
            </div>
              <div class="form-group" style="margin: 0px;">
                <input
                  v-bind:class="errors[0] ? 'form-control input-error' : 'form-control'"
                  v-model.number="maxCurrent"
                  @change="validate">                        
              </div>       
            <label class="input-error">{{errors[0]}}</label>
          </div>     
        </ValidationProvider>        
      </div>
      </ValidationObserver>
    </form>

    
  </card>

  <div v-show="loadedData.current.loadedFlag" class="row">
    <div class="col-12">
      <card>
      <!--<card type="chart">-->
        <!--<template slot="header">-->
          <div class="row">
            <div class="col">
              <h2 class="card-title">{{currentBmsAlias}} - {{$t('commons.current')}} (A)</h2>
            </div>
          </div>
        <!--</template>-->
        <div>
          <div id="chart-current" v-bind:class="reactRefreshHackClassObject">
          </div>
        </div>
      <div class="card-footer text-right">
        <base-button type="primary-nogradient" @click="queryToCsv(buildQuery('cu', currentRange, true), 'current')">{{$t('chargeGraph.exportCsvButton')}}</base-button>
      </div>
      </card>
    </div>
  </div>

  <div v-show="loadedData.voltage.loadedFlag" class="row">
    <div class="col-12">
      <card>
      <!--<card type="chart">-->
        <!--<template slot="header">-->
          <div class="row">
            <div class="col">
              <h2 class="card-title">{{currentBmsAlias}} - {{$t('commons.voltage')}} (V)</h2>
            </div>
          </div>
        <!--</template>-->
        <div>
          <div id="chart-voltage" v-bind:class="reactRefreshHackClassObject">
          </div>
        </div>
      <div class="card-footer text-right">
        <base-button type="primary-nogradient" @click="queryToCsv(buildQuery('vo', currentRange, true), 'voltage')">{{$t('chargeGraph.exportCsvButton')}}</base-button>
      </div>
      </card>
    </div>
  </div>

  <div v-show="loadedData.charge.loadedFlag" class="row">
    <div class="col-12">
      <card>
      <!--<card type="chart">-->
        <!--<template slot="header">-->
          <div class="row">
            <div class="col">
              <h2 class="card-title">{{currentBmsAlias}} - {{$t('commons.charge')}} (%)</h2>
            </div>
          </div>
        <!--</template>-->
        <div>
          <div id="chart-charge" v-bind:class="reactRefreshHackClassObject">
          </div>
        </div>
      <div class="card-footer text-right">
        <base-button type="primary-nogradient" @click="queryToCsv(buildQuery('ch', currentRange, true), 'charge')">{{$t('chargeGraph.exportCsvButton')}}</base-button>
      </div>
      </card>
    </div>
  </div>

  <div v-show="loadedData.temperature.loadedFlag" class="row">
    <div class="col-12">
      <card>
      <!--<card type="chart">-->
        <!--<template slot="header">-->
          <div class="row">
            <div class="col">
              <h2 class="card-title">{{currentBmsAlias}} - {{$t('commons.temperature')}} (°C)</h2>
            </div>
          </div>
        <!--</template>-->
        <div>
          <div id="chart-temperature" v-bind:class="reactRefreshHackClassObject">
          </div>
        </div>
      <div class="card-footer text-right">
        <base-button type="primary-nogradient" @click="queryToCsv(buildQuery('t1', currentRange, true), 'temperature')">{{$t('chargeGraph.exportCsvButton')}}</base-button>
      </div>
      </card>
    </div>
  </div>

</div>
</template>
<script>
import Card from '@/components/Cards/Card.vue';
import {InfluxDB, FluxTableMetaData} from '@influxdata/influxdb-client'
import {queryToTable} from '@influxdata/influxdb-client-giraffe'
import * as Giraffe from  '@influxdata/giraffe' 
import { url, org, adminBucket } from '@/config/env'
import React from 'react'
import ReactDOM from 'react-dom'
import { saveAs } from "file-saver";
import { unparse } from "papaparse";
import { mapState } from 'vuex'
import { extend } from 'vee-validate';
import { required, integer, between } from 'vee-validate/dist/rules';

const defaultMinVoltage = 0
const defaultMaxVoltage = 100
const defaultMinCurrent = -500
const defaultMaxCurrent = 200

// Assuming 1 datapoint per ms
const maxDataPoints = 10000
const currentDomain = [defaultMinCurrent, defaultMaxCurrent]
const voltageDomain = [defaultMinVoltage, defaultMaxVoltage]
const temperatureDomain = [-20, 70]
const chargeDomain = [0, 100]

const style = {
  height: "calc(30vh)",
  margin: "20px",
  "textAlign": "left"
}

const commonGiraffeConfigs = { 
  onSetYDomain: () => {},
  onResetYDomain: () => {},
  gridColor: '#8e91a1',
  gridOpacity: 0.5,
  //axisColor: '#8e91a1',
  axisColor: '#8e91a1',
  axisOpacity: 0.5,
  legendBackgroundColor: '#1c1f20',
  legendFont: '',
  tickFont: '15px sans-serif'
}

const baseGiraffeLineLayerConfig = {
  type: 'line',
  x: '_time',
  y: '_value',
  lineWidth: 1,
  shadeBelow: true,
  shadeBelowOpacity: 0.1
}

// DO NOT SHARE CONFIG OBJECTS

const timeFormatter = Giraffe.timeFormatter({
      timeZone: 'Europe/Rome',
      format: '  DD/MM/YYYY HH:mm:ss',
    })

const currentConfig = { 
    layers: [{
      ...baseGiraffeLineLayerConfig,
      maxTooltipRows: 120
    }],
    valueFormatters: {
      _time: timeFormatter,
      _value: (num) => num.toFixed(1)+' A'
    },
    legendColumns: ["_time", "_value"],
    yDomain: currentDomain,
    ...commonGiraffeConfigs
  };

const voltageConfig = { 
    layers: [{
      ...baseGiraffeLineLayerConfig,
      colors: ['orange']
    }],
    valueFormatters: {
      _time: timeFormatter,
      _value: (num) => num.toFixed(1)+' V'
    },
    yDomain: voltageDomain,
    ...commonGiraffeConfigs
  };

const temperatureConfig = { 
    layers: [ baseGiraffeLineLayerConfig ],
    valueFormatters: {
      _time: timeFormatter,
      _value: (num) => num.toFixed(1)+' °C'
    },
    yDomain: temperatureDomain,
    ...commonGiraffeConfigs
  };  

const chargeConfig = { 
    layers: [{
      ...baseGiraffeLineLayerConfig,
      colors: ['green']
    }],
    valueFormatters: {
      _time: timeFormatter,
      _value: (num) => num.toFixed(1)+'%'
    },
    legendColumns: ["_time", "_value"],
    yDomain: chargeDomain,
    ...commonGiraffeConfigs
  };  

export default {
    components: {
        Card
  },
  data() {
    return {
      timer: null,
      selectedUser: {},
      queryApi: null,
      selectedBMS: {},
      loadedBMS: '',
      loadedData: {
        current: { loadedFlag: false, loadedBMS: '' },
        voltage: { loadedFlag: false, loadedBMS: '' },
        temperature: { loadedFlag: false, loadedBMS: '' },
        charge: { loadedFlag: false, loadedBMS: '' }
      },
      range: {
        start: new Date((new Date((new Date).setHours(0))).setMinutes(0)),
        end: new Date((new Date((new Date).setHours(23))).setMinutes(59)),
      },
      currentRange: {},
      voltageRange: {},
      temperatureRange: {},
      chargeRange: {},
      extraInputVisible: false,
      minVoltage: defaultMinVoltage,
      maxVoltage: defaultMaxVoltage,
      minCurrent: defaultMinCurrent,
      maxCurrent: defaultMaxCurrent,
      graphRangeModified: false,
      reactRefreshHackClassObject: {
        'react-hack': false
      }
    };
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.importGraphRangesFromStore())
  },
  computed: {
    ...mapState([
      'logged',
      'loggedUserEmail',
      'isAdmin',
      'isLvl1User',
      'users',
      'bmss',
      'loggedUserPreferences',
      'whiteTheme',
      'calendarColor'
    ]),
    getUsers () {
      return this.users.filter(user => user.bucket && user.token)
    },
    getBMSs () {   
      //return this.selectedUser.bmsList
      return this.bmss.filter(bms => bms.owner == this.selectedUser.email)
    },
    rows() {
      return this.items.length
    },
    canQuery() {
      // Unable to check the validationObserver in this method because validationObserver.validate() is async 
      // and this method cannot be async otherwise the button won't be updated
      // So the individual values are re-validated
      return this.selectedBMS != '' && Object.keys(this.selectedBMS).length !== 0 &&
             this.selectedUser != '' && Object.keys(this.selectedUser).length !== 0 &&
             this.range.start != '' &&
             this.range.end != '' &&
             integer.validate(this.minCurrent) && integer.validate(this.maxCurrent) && integer.validate(this.minVoltage) && integer.validate(this.maxVoltage) &&
             !this.dateEquals(this.range.start, this.range.end)
    },
    currentBmsAlias() {
      const loadedBms = this.loadedData.current.loadedBMS;
      if(loadedBms && this.bmss) {
        var bms = this.bmss.find(bms => bms.bms_id == loadedBms)
        if(bms && bms.alias)
          return bms.alias
      }
      return loadedBms
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    whiteTheme(newValue, oldValue) {
      this.toggleChartTheme(newValue)
    },
    locale : function () {
      this.localizeValidationErrorMessages()
    },
    minVoltage() {
      this.graphRangeModified = true;
    },
    maxVoltage() {
      this.graphRangeModified = true;
    },
    minCurrent() {
      this.graphRangeModified = true;
    },
    maxCurrent() {
      this.graphRangeModified = true;
    }
  },
  mounted() {
    if(!this.isAdmin) this.selectedUser = this.users[0]
    this.initializeChartTheme()
    this.localizeValidationErrorMessages()
  },
  methods: {
    importGraphRangesFromStore() {
      if(this.loggedUserPreferences) {
        this.minVoltage = this.loggedUserPreferences.graphMinVoltage || this.minVoltage 
        this.maxVoltage = this.loggedUserPreferences.graphMaxVoltage || this.maxVoltage 
        this.minCurrent = this.loggedUserPreferences.graphMinCurrent || this.minCurrent 
        this.maxCurrent = this.loggedUserPreferences.graphMaxCurrent || this.maxCurrent 
      }
    },
    localizeValidationErrorMessages() {
      extend('required', {
        ...required,
        message: this.$t('validationErrorMessages.required')
      });
      extend('integer', {
        ...integer,
        message: this.$t('validationErrorMessages.integer')
      });
      extend('between', {
        ...between,
        params: ['min', 'max'],
        message: this.$t('validationErrorMessages.between')
      });
    },
    toggleExtraInput() {
      this.extraInputVisible = !this.extraInputVisible
    },
    queryToCsv(query, fileName) {
      var outerScope = this
      var data = []
      this.queryApi.queryRows(query, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          data.push(o)    
        },
        error(error) {
          console.error(error)
          this.$notify({type: 'danger', message: 'An error has occurred'})
        },
        complete() {
          outerScope.generateCsv(data, fileName)
        },
      })
    },
    generateCsv(dataExport, fileName) {
      if (!dataExport) {
        console.error("No data to export");
        return;
      }

      var advancedOptions = {
        type: Object,
        default: () => {}
      }

      let csv = unparse(
        dataExport,
        Object.assign(
          {
            delimiter: ',',
            encoding: "utf-8"
          },
          advancedOptions
        )
      );

      //Add BOM when UTF-8
      csv = "\ufeff" + csv;

      let blob = new Blob([csv], {
        type: "application/csvcharset=" + this.encoding
      });
      saveAs(blob, fileName+'.csv');
    },
    dateEquals(a, b) {
      // https://stackoverflow.com/questions/4587060/determining-date-equality-in-javascript
      return (a >= b && a <= b)
    },
    computeAggregationWindowIntervalString(maxDataPoints, startDateTime, endDateTime) {
      const second = 1000
      const minute = 60*second
      const hour = 60*minute
      const day = 24*hour

      var timeRangeMs = endDateTime - startDateTime
      var aggregationIntervalMs = Math.floor(timeRangeMs / maxDataPoints)

      if(aggregationIntervalMs < 1) aggregationIntervalMs = 1

      if(aggregationIntervalMs < second)
        return aggregationIntervalMs+'ms'
      if(aggregationIntervalMs < minute)
        return Math.floor(aggregationIntervalMs/second)+'s'
      if(aggregationIntervalMs < hour)
        return Math.floor(aggregationIntervalMs/minute)+'m'
      if(aggregationIntervalMs < day)
        return Math.floor(aggregationIntervalMs/hour)+'h'
      else
        return Math.floor(aggregationIntervalMs/day)+'d'
    },
    renderGiraffePlot(config, error = null){
      if (error){
        // render error message
        return React.createElement('center', null, error.toString())
      } else if (config.table.length) {
        // render giraffe plot
        const plot = React.createElement(Giraffe.Plot, {config: config})
        return React.createElement('div', {style}, plot);
      } else {
        // render empty table recevied
        return React.createElement('center', null, this.$t('chargeGraph.noData'))
      }
    },
    tableParseCurrentThreshold(table, giraffeConfig) {
        var threshold = 0
        var flagPositive = false
        var phaseNum = 0
        var labels = []
        var colors = []

        var dataColumnName = giraffeConfig.layers[0].y
        if(table.columns[dataColumnName].data[0] <= threshold) {
          flagPositive = false
          colors.push('red')
        } else {
          flagPositive = true
          colors.push('green')
        }

        table.columns[dataColumnName].data.forEach(dataPoint => {
          if(flagPositive && dataPoint <= threshold) {
            flagPositive = false
            colors.push('red')
            phaseNum++
          }
          if(!flagPositive && dataPoint > threshold) {
            flagPositive = true
            colors.push('green')
            phaseNum++
          }
          labels.push('phase'+phaseNum)
        });

        var lineLayer = giraffeConfig.layers[0]
        lineLayer.fill = ["phase"]
        lineLayer.colors = colors

        const newTable = table.addColumn('phase', 'string', 'string', labels);

        giraffeConfig.table = newTable
        giraffeConfig.layers = [lineLayer]

        return giraffeConfig
    }, 
    tableParseShortCharge(table, giraffeConfig) {

      // If a charghe starts over the start threshold or ends before the end threshold, it is considered a short charge
      const shortChargeStartThreshold = 80
      const shortChargeEndThreshold = 95
      const stableValuesThreshold = 50

      const states = Object.freeze({
        CHARGING: 'CHARGING',
        SHORT_CHARGING: 'SHORT_CHARGING',
        DISCHARGING: 'DISCHARGING',
        STABLE: 'STABLE',
      })

      // A finite state machine representing the charging state
      const chargeFSM = {
        state: states.STABLE,
        currentChargeValue: 0,
        stableValuesCounter: 0,
        phaseNum: 0,
        labels: [],
        colors: [],
        changeState(newState) {
          
          console.log("FSM entering state " + newState)
          switch (newState) {          
            case states.CHARGING:
              this.phaseNum++
              this.colors.push('green')
              break;
            case states.SHORT_CHARGING:
              this.phaseNum++
              this.colors.push('red')
              break;
            case states.DISCHARGING:
              if(this.state == states.CHARGING || this.state == states.SHORT_CHARGING) {
                this.phaseNum++
                this.colors.push('green')
              }
              break;
            case states.STABLE:
                if(this.state == states.CHARGING || this.state == states.SHORT_CHARGING) {
                  this.phaseNum++
                  this.colors.push('green')
                }
              break;         
            default:
              break;
          }
          
          console.log(this.phaseNum)
          this.state = newState
        },
        // Determines if the charge that's been started will be a good one or not
        initiateCharge() {
          if(this.currentChargeValue > shortChargeStartThreshold)
            this.changeState(states.SHORT_CHARGING)
          else
            this.changeState(states.CHARGING)
        },
        // Check if the charge that ended was a good one or not
        checkChargeEnd() {
          if(this.currentChargeValue < shortChargeEndThreshold) {
            this.colors[this.colors.length-1] = '#ff8717'
            console.log("Charge terminated abruptly")
          }
        },
        // Check for how long the charge value it remained stable
        checkStableValues() {
          this.stableValuesCounter = this.stableValuesCounter + 1
          if(this.stableValuesCounter >= stableValuesThreshold) {
            this.stableValuesCounter = 0
            if(this.state == states.CHARGING)
              this.checkChargeEnd()
            this.changeState(states.STABLE)
          }
        },
        resetStableCounter() {
          this.stableValuesCounter = 0
        },
        resetFSM() {
          this.state = states.STABLE
          this.currentChargeValue = 0
          this.stableValuesCounter = 0
          this.phaseNum = 0
          this.labels = []
          this.colors = []
        },
        computeNextState(chargeValue) {
          var previousChargeValue = this.currentChargeValue        
          if(chargeValue > previousChargeValue)
            this.transitions[this.state].chargeIncreased()
          else if(chargeValue < previousChargeValue)
            this.transitions[this.state].chargeDecreased()
          else
            this.transitions[this.state].chargeStable()

          this.currentChargeValue = chargeValue
          this.labels.push('phase'+this.phaseNum)
        }
      }

      chargeFSM.transitions = {
        CHARGING: {
          chargeIncreased() {
            chargeFSM.resetStableCounter()
          },
          chargeDecreased() {
            chargeFSM.resetStableCounter()
            chargeFSM.changeState(states.DISCHARGING)
          },
          chargeStable() {             
            chargeFSM.checkStableValues()
          },
        },
        SHORT_CHARGING: {
          chargeIncreased() {
            chargeFSM.resetStableCounter()
          },
          chargeDecreased() {
            chargeFSM.resetStableCounter()
            chargeFSM.checkChargeEnd()
            chargeFSM.changeState(states.DISCHARGING)
          },
          chargeStable() {
            chargeFSM.checkStableValues()
          },
        },
        DISCHARGING: {
          chargeIncreased() {
            chargeFSM.initiateCharge()
          },
          chargeDecreased() {
          },
          chargeStable() {
            chargeFSM.checkStableValues()
          },
        },
        STABLE: {
          chargeIncreased() {
            chargeFSM.initiateCharge()
          },
          chargeDecreased() {
            chargeFSM.changeState(states.DISCHARGING)
          },
          chargeStable() {
          },
        }
      }

      var dataColumnName = giraffeConfig.layers[0].y
      var dataValues = table.columns[dataColumnName].data

      chargeFSM.currentChargeValue = dataValues[0]
      chargeFSM.colors.push('green')
      chargeFSM.labels.push('phase0')

      for (let i = 1; i < dataValues.length; i++) {
        chargeFSM.computeNextState(dataValues[i]);       
      }

      console.log("Parsing completed")
      console.log(chargeFSM.colors)
      console.log(chargeFSM.phaseNum)

      var lineLayer = giraffeConfig.layers[0]
      lineLayer.fill = ["phase"]
      lineLayer.colors = chargeFSM.colors

      const newTable = table.addColumn('phase', 'string', 'string', chargeFSM.labels);

      giraffeConfig.table = newTable
      giraffeConfig.layers = [lineLayer]

      return giraffeConfig
      
      //TODO fixare il problema che il primo punto è sempre verde con:
      // labels[0] = labels[1]

    },
    queryAndRender(query, bms, giraffeConfig, seriesName, domId, loadedFlagPointer, tableParser = null) {
      console.log(query)
      var outerScope = this
      queryToTable(
        this.queryApi,
        query,
        Giraffe.newTable
      ). then(table => {
        console.log("Datapoint fetched: " + table.length)
          
        if(tableParser && table.length > 0) {
          giraffeConfig = tableParser(table, giraffeConfig)
        }
        else{
          giraffeConfig.table = table
        }

        if(table.length) {
          table.columns._time.name = 'Time'
          table.columns._value.name = seriesName
          
          // Force the graph to zoom between first and last available data
          const timeValues = table.columns._time.data
          const firstDataPointTime = timeValues[0]
          const lastDataPointTime = timeValues[timeValues.length - 1]
          giraffeConfig.xDomain = [firstDataPointTime, lastDataPointTime]          
        }
        ReactDOM.render(       
            outerScope.renderGiraffePlot(giraffeConfig),
            document.getElementById(domId)
        );
        loadedFlagPointer.loadedBMS = bms
        loadedFlagPointer.loadedFlag = true
      }). catch(error => {
        console.log('queryToTable fails', error) 
        ReactDOM.render(
            outerScope.renderGiraffePlot({}, error),
            document.getElementById(domId)
        );
        loadedFlagPointer.loadedBMS = bms
        loadedFlagPointer.loadedFlag = true
      })
    },
    loadData() {
      this.queryApi = new InfluxDB({url, token: this.selectedUser.token}).getQueryApi(org)

      this.loadedBMS = this.selectedBMS

      this.loadedData.current.loadedFlag = false
      this.loadedData.voltage.loadedFlag = false
      this.loadedData.temperature.loadedFlag = false
      this.loadedData.charge.loadedFlag = false

      this.currentRange = {...this.range}
      this.voltageRange = {...this.range}
      this.temperatureRange = {...this.range}
      this.chargeRange = {...this.range}

      if(this.logged) {
        this.setGiraffeControlledMode()
      } else {
        this.setGiraffeDefaultMode()
      }

      currentConfig.yDomain = [Number(this.minCurrent), Number(this.maxCurrent)]
      voltageConfig.yDomain = [Number(this.minVoltage), Number(this.maxVoltage)]

      this.queryAndRender(this.buildQuery('cu', this.currentRange, false), this.selectedBMS, currentConfig, 'Current', 'chart-current', this.loadedData.current, this.tableParseCurrentThreshold)
      this.queryAndRender(this.buildQuery('vo', this.voltageRange, false), this.selectedBMS, voltageConfig, 'Voltage', 'chart-voltage', this.loadedData.voltage)
      this.queryAndRender(this.buildQuery('t1', this.temperatureRange, false), this.selectedBMS, temperatureConfig, 'Temperature', 'chart-temperature', this.loadedData.temperature)
      this.queryAndRender(this.buildQuery('ch', this.chargeRange, false), this.selectedBMS, chargeConfig, 'Charge', 'chart-charge', this.loadedData.charge, this.tableParseShortCharge)
      this.forceReactRefresh();

      if(this.graphRangeModified == true)
        this.updateUserGraphRangePreferences();
    },
    setGiraffeDefaultMode() {
      currentConfig.xDomain = null
      currentConfig.onSetXDomain = null
      currentConfig.onResetXDomain = null
      voltageConfig.xDomain = null
      voltageConfig.onSetXDomain = null
      voltageConfig.onResetXDomain = null
      temperatureConfig.xDomain = null
      temperatureConfig.onSetXDomain = null
      temperatureConfig.onResetXDomain = null
      chargeConfig.xDomain = null
      chargeConfig.onSetXDomain = null
      chargeConfig.onResetXDomain = null
    },
    setGiraffeControlledMode() {
      // If the 3 config parameters xDomain, onSetXDomain, onResetXDomain are all set, giraffe works in "controlled mode"
      
      currentConfig.xDomain = null
      //currentConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
      currentConfig.onSetXDomain = (range) => {
        const newRange = {start: new Date(range[0]), end: new Date(range[1])}
        currentConfig.xDomain = [newRange.start.getTime(), newRange.end.getTime()]
        this.currentRange = newRange
        this.queryAndRender(this.buildQuery('cu', newRange, false), this.loadedBMS, currentConfig, 'Current', 'chart-current', this.loadedData.current, 0)
      }
      currentConfig.onResetXDomain = () => {
        currentConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
        this.currentRange = {...this.range}
        this.queryAndRender(this.buildQuery('cu', this.range, false), this.loadedBMS, currentConfig, 'Current', 'chart-current', this.loadedData.current, 0)
      }

      voltageConfig.xDomain = null
      //voltageConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
      voltageConfig.onSetXDomain = (range) => {
        const newRange = {start: new Date(range[0]), end: new Date(range[1])}
        voltageConfig.xDomain = [newRange.start.getTime(), newRange.end.getTime()]
        this.voltageRange = newRange
        this.queryAndRender(this.buildQuery('vo', newRange, false), this.loadedBMS, voltageConfig, 'Voltage', 'chart-voltage', this.loadedData.voltage)
      }
      voltageConfig.onResetXDomain = () => {
        voltageConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
        this.voltageRange = {...this.range}
        this.queryAndRender(this.buildQuery('vo', this.range, false), this.loadedBMS, voltageConfig, 'Voltage', 'chart-voltage', this.loadedData.voltage)
      }

      temperatureConfig.xDomain = null
      //temperatureConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
      temperatureConfig.onSetXDomain = (range) => {
        const newRange = {start: new Date(range[0]), end: new Date(range[1])}
        temperatureConfig.xDomain = [newRange.start.getTime(), newRange.end.getTime()]
        this.temperatureRange = newRange
        this.queryAndRender(this.buildQuery('t1', newRange, false), this.loadedBMS, temperatureConfig, 'Temperature', 'chart-temperature', this.loadedData.temperature)
      }
      temperatureConfig.onResetXDomain = () => {
        temperatureConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
        this.temperatureRange = {...this.range}
        this.queryAndRender(this.buildQuery('t1', this.range, false), this.loadedBMS, temperatureConfig, 'Temperature', 'chart-temperature', this.loadedData.temperature)
      }

      chargeConfig.xDomain = null
      //chargeConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
      chargeConfig.onSetXDomain = (range) => {
        const newRange = {start: new Date(range[0]), end: new Date(range[1])}
        chargeConfig.xDomain = [newRange.start.getTime(), newRange.end.getTime()]
        this.chargeRange = newRange
        this.queryAndRender(this.buildQuery('ch', newRange, false), this.loadedBMS, chargeConfig, 'charge', 'chart-charge', this.loadedData.charge)
      }
      chargeConfig.onResetXDomain = () => {
        chargeConfig.xDomain = [this.range.start.getTime(), this.range.end.getTime()]
        this.chargeRange = {...this.range}
        this.queryAndRender(this.buildQuery('ch', this.range, false), this.loadedBMS, chargeConfig, 'charge', 'chart-charge', this.loadedData.charge)
      }
    },
    getBucket() {
      //return this.logged ? adminBucket : bucket
      return this.selectedUser.bucket
    },
    buildQuery(field, range, unaggregated) {
      return `from(bucket: "${this.getBucket()}") 
              |> range(start: ${range.start.toISOString()}, stop: ${range.end.toISOString()})
              |> filter(fn: (r) => r._measurement == "tl")
              |> filter(fn: (r) => r.bm == "${this.loadedBMS}")
              |> filter(fn: (r) => (r._field == "${field}"))` +
              (unaggregated ? '': this.getAggregationWindow(range)) +
             `|> group(columns: ["bm"])
              |> sort(columns: ["_time"])`
    },
    getAggregationWindow(range) {
      if(!this.logged) return ''
      var aw = this.computeAggregationWindowIntervalString(maxDataPoints, range.start, range.end)
      //return `|> aggregateWindow(every: ${aw}, fn: last, createEmpty: false)`
      return `|> aggregateWindow(every: ${aw}, fn: mean, createEmpty: false)`
    },
    async updateUserGraphRangePreferences() {
      this.loggedUserPreferences.graphMinVoltage = this.minVoltage
      this.loggedUserPreferences.graphMaxVoltage = this.maxVoltage
      this.loggedUserPreferences.graphMinCurrent = this.minCurrent
      this.loggedUserPreferences.graphMaxCurrent = this.maxCurrent
      this.graphRangeModified = false
      this.$store.commit('setLoggedUserPreferences', this.loggedUserPreferences)

      console.log("Updating user preferences")
      console.log(this.loggedUserPreferences)
      this.axios.post(`https://fct3xrgu26.execute-api.eu-central-1.amazonaws.com/default/setUserPreferences?id=${this.loggedUserEmail}`, this.loggedUserPreferences).then((response) => {     
        console.log(response)
      }).catch(error => {
        console.log(error)
      });
    },
    startTimer() {
      this.timer = new Date()
      console.log("Timer started")
    },
    lapTimer() {
      var lap = new Date()
      var timeDiff = lap - this.timer
      this.timer = lap
      console.log(timeDiff + "ms elapsed")
    },
    initializeChartTheme(isWhite) {
      if(this.whiteTheme) {
        currentConfig.tickFontColor = 'black'
        voltageConfig.tickFontColor = 'black'
        temperatureConfig.tickFontColor = 'black'
        chargeConfig.tickFontColor = 'black'
      } else {
        currentConfig.tickFontColor = 'white'
        voltageConfig.tickFontColor = 'white'
        temperatureConfig.tickFontColor = 'white'
        chargeConfig.tickFontColor = 'white'
      }
    },
    toggleChartTheme(isWhite) {
      var textColor = isWhite ? 'black' : 'white'
      
      if(this.loadedData.current.loadedFlag) {
        currentConfig.tickFontColor = textColor
        ReactDOM.render(       
              this.renderGiraffePlot(currentConfig),
              document.getElementById('chart-current')
          );
      }
      if(this.loadedData.voltage.loadedFlag) {
        voltageConfig.tickFontColor = textColor
        ReactDOM.render(       
              this.renderGiraffePlot(voltageConfig),
              document.getElementById('chart-voltage')
          );
      }
      if(this.loadedData.temperature.loadedFlag) {
        temperatureConfig.tickFontColor = textColor
        ReactDOM.render(       
              this.renderGiraffePlot(temperatureConfig),
              document.getElementById('chart-temperature')
          );
      }
      if(this.loadedData.charge.loadedFlag) {
        chargeConfig.tickFontColor = textColor
        ReactDOM.render(       
              this.renderGiraffePlot(chargeConfig),
              document.getElementById('chart-charge')
          );
      }
      this.forceReactRefresh();
    },
    forceReactRefresh() {
      // BUG: Upon fetching new data, giraffe's react component doesn't repaint automatically. But the component is refreshed upon resizing window...
      // Ugly hack: Applying a different padding to the div containing the react component each time, a refresh of the component is forced 
      this.reactRefreshHackClassObject['react-hack'] = !this.reactRefreshHackClassObject['react-hack'];

      // Alternatively, recreate the element. Doesn't work with new queries though
      /*
      ReactDOM.render(       
        React.createElement('div', null, 'Hello World'),
        document.getElementById('chart-current')
      );
      ReactDOM.render(       
        this.renderGiraffePlot(currentConfig),
        document.getElementById('chart-current')
      );
      */
    }
  }
};
</script>
<style src="@/assets/css/input-bar.css"/>
<style>

.white-content .giraffe-tooltip{
  background-color: white !important
}

.white-content .giraffe-tooltip-column-header{
  color: black !important
}

.react-hack {
  padding-right: 1px;
}

</style>
