<template>
  <div>
    <card v-if="false">
      <form @submit.prevent>
        <div class="form-row">
          <base-input v-if="isAdmin" class="col-md-3" placeholder="Bms">
              <select v-model="selectedUser" id="inputState" class="form-control">
                <option :value="{}" disabled>{{$t('commons.userSelectPlaceholder')}}</option>
                <option
                  v-for="(user) in users"
                  :value="user"
                  :key="user.email"
                >{{user.username}}</option>
              </select>             
            </base-input>
        </div>
      </form>
    </card>
    <div class="row">
      <div class="col-12">
        <card :title="$t('customerPanel.cardTitle')">
          <div class="table-responsive">
            <b-table 
              striped 
              hover 
              sticky-header
              id="my-table"
              :fields="fields"
              :items="renters"
              selectable
              :select-mode="'single'"
              :per-page="perPage"
              :current-page="currentPage">
              <template #cell(edit)="data">
                <button class="btn btn-link" @click="rowSelected(data.item)" >
                  <i class='tim-icons icon-pencil'></i>
                </button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <base-button slot="footer" type="primary-nogradient" @click="newRenter()" fill>{{$t('customerPanel.newCustomerButton')}}</base-button>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Card from '@/components/Cards/Card.vue';

export default {
    components: {
        Card
  },
  data() {
    return {
      selectedUser: {},
      perPage: 15,
      currentPage: 1,
      fields: [],
      selected: []
    };
  },
  watch: {
    locale : function (newLocale, oldLocale) {
      this.tableFields = this.computeTableFields(); 
    }
  },
  computed: {
    rows() {
      return this.renters.length
    },
    ...mapState([
      'logged',
      'isAdmin',
      'users',
      'renters',
      'whiteTheme',
      'calendarColor'
    ]),
    locale() {
      return this.$i18n.locale
    },
  },
  mounted() {
    this.$store.dispatch('updateRenters')
    this.fields = this.computeTableFields()
  },
  methods: {
    rowSelected(renter) {
      if(renter) {
        localStorage.setItem("selectedRenter", JSON.stringify(renter))
        this.$router.push('edit-renter')
      }
    },
    newRenter() {
      localStorage.setItem("selectedRenter", JSON.stringify({}))
      this.$router.push('edit-renter')
    },
    computeTableFields() {
      let fields = [
        {
          key: 'name',
          label: this.$i18n.t('customerPanel.customerName')
        },{
          key: 'company',
          label: this.$i18n.t('customerPanel.company')
        },{
          key: 'address',
          label: this.$i18n.t('customerPanel.address')
        },{
          key: 'edit',
          label: this.$i18n.t('customerPanel.edit')},
      ]

      if(this.isAdmin) {
        fields.unshift({
          key: 'creator',
          label: this.$i18n.t('customerPanel.user')})
        fields.unshift({
          key: 'creatorName',
          label: this.$i18n.t('customerPanel.username')})      
      }
      return fields
    },
  }
};
</script>
