<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card title="BMS">
          <div class="table-responsive">
            <b-table 
              striped 
              hover 
              sticky-header
              id="my-table"
              :fields="fields"
              :items="bmss"
              selectable
              :select-mode="'single'"
              :per-page="perPage"
              :current-page="currentPage">
              <template #cell(vNominal)="data">
                {{ data.value ? data.value + ' V' : '' }}
              </template>
              <template #cell(cNominal)="data">
                {{ data.value ? data.value + ' Ah' : '' }}
              </template>
              <template #cell(techBatt)="data">
                {{ batteryTechEnum[data.value] ? batteryTechEnum[data.value] : data.value }}
              </template>
              <template #cell(received)="data">
                <b>{{ data.item.received ? "&#9989;" : ( data.item.configured ? "&#128260;" : "&#10060;") }}</b>
              </template>
              <template #cell(edit)="data">
                <button class="btn btn-link" @click="rowSelected(data.item)" >
                  <i class='tim-icons icon-pencil'></i>
                </button>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <!--
          <base-button slot="footer" type="primary-nogradient" @click="newBms()" fill>New Bms</base-button>
          -->
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Card from '@/components/Cards/Card.vue';
import { batteryTechEnum } from '@/config/env'

export default {
    components: {
        Card
  },
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      batteryTechEnum: batteryTechEnum,
      fields: [],
      selected: [],
      nextToken: ''
    };
  },
  watch: {
    locale : function () {
      this.fields = this.computeTableFields(); 
    }
  },
  computed: {
    rows() {
      return this.bmss.length
    },
    ...mapState([
      'logged',
      'isAdmin',
      'isLvl1User',
      'bmss',
      'whiteTheme',
      'calendarColor'
    ]),
    locale() {
      return this.$i18n.locale
    },
  },
  mounted() {
    this.$store.dispatch('updateBMSs')
    var handler = setInterval(function () {
      this.$store.dispatch('updateBMSs')
    }.bind(this), 5000)   
    this.$store.commit('addTimerHandler', handler)
    this.fields = this.computeTableFields();
  },
  methods: {
    ...mapMutations([
      'setBMSs'
    ]),
    rowSelected(bms) {
      if(bms) {
        console.log("Selected bms")
        console.log(bms)
        localStorage.setItem("selectedBms", JSON.stringify(bms))
        this.$router.push('edit-bms')
      }
    },
    newBms() {
      localStorage.setItem("selectedBms", JSON.stringify({}))
      this.$router.push('edit-bms')
    },
    computeTableFields() {
      let fields = [{
          key: 'bms_id',
          label: 'id'},
        {
          key: 'renterName',
          label: this.$i18n.t('bmsPanel.renter')},
        {
          key: 'alias',
          label: this.$i18n.t('bmsPanel.alias')},
        {
          key: 'vNominal',
          label: this.$i18n.t('bmsPanel.nominalVoltage')},
        {
          key: 'cNominal',
          label: this.$i18n.t('bmsPanel.nominalCapacity')},
        {
          key: 'techBatt',
          label: this.$i18n.t('bmsPanel.batteryTech')},
        {
          key: 'received',
          label: this.$i18n.t('bmsPanel.configured')},
        {
          key: 'edit',
          label: this.$i18n.t('bmsPanel.edit')},
        ]
      if(this.isAdmin || this.isLvl1User) fields.unshift({
        key: 'ownerName',
        label: this.$i18n.t('bmsPanel.username')},
      );
      return fields
    }
  }
};
</script>
