<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <h5 slot="header" class="title">{{$t('editBms.cardTitle')}}</h5>
        <div class="row">
          <div class="col-md-2">
            <base-input label="ID"
                      v-model="model.bms_id"
                      >
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input :label="$t('editBms.alias')"
                      v-model="model.alias"
                      >
            </base-input>
          </div>
          <div class="col-md-2">
            <base-input :label="$t('editBms.customer')"
                      disabled
                      v-model="model.renterName"
                      >
            </base-input>
          </div>
          <div class="col-md-3">
            <base-input :label="$t('editBms.owner')"
                      disabled
                      v-model="model.ownerName"
                      >
            </base-input>
          </div>
        </div>

        <div class="row">

          <!-- Nominal voltage doesn't apply for LIPOLY and LIFEPO -->
          <template v-if="model.techBatt == 5 || model.techBatt == 6">
            <div  class="col-md-1">
              <base-input :label="$t('editBms.numCells')"
                      v-model.number="model.numCells">
              </base-input>
            </div>
            <div  class="col-md-2">
              <base-input :label="$t('editBms.cellsNominalVoltage')"
                      v-model.number="model.cellsNominalVoltage">
              </base-input>
            </div>
          </template>
          <div v-else class="col-md-3">
            <base-input :label="$t('editBms.nominalVoltage')">
              <select v-model="model.vNominal" id="inputState" class="form-control">
                <option
                  v-for="[key, value] in Object.entries(voltageEnum)"
                  :value="key"
                  :key="key"
                >{{value}}</option>
              </select> 
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input :label="$t('editBms.nominalCapacity')"
                      v-model="model.cNominal"
                      >
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input :label="$t('editBms.batteryTech')">
              <select v-model="model.techBatt" id="inputState" class="form-control">
                <option
                  v-for="[key, value] in Object.entries(batteryTechEnum)"
                  :value="key"
                  :key="key"
                >{{value}}</option>
              </select> 
            </base-input>
          </div>

        </div>
        
        <div class="row">

          <div class="col-md-3">
            <base-input :label="$t('editBms.batteryType')"
                      v-model="model.typeBatt"
                      >
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input :label="$t('editBms.manufactured')"
                      v-model="model.manufacturer"
                      >
            </base-input>
          </div>

          <div class="col-md-3">
            <base-input :label="$t('editBms.yearOfManufacture')"
                      v-model="model.yearConst"
                      >
            </base-input>
          </div>
        </div>
        <div slot="footer" class="button-row">
          <div class="button-container">
            <base-button slot="footer" type="primary-nogradient" @click="saveBms()" fill>{{$t('editBms.saveBmsButton')}}</base-button>
          </div>
          <div class="button-container">
            <base-button slot="footer" type="primary-nogradient" @click="back()" fill>{{$t('editBms.backButton')}}</base-button>
          </div>
          <div v-if="isAdmin" class="button-container">
            <base-button slot="footer" type="danger-nogradient" @click="deleteBms()" fill>{{$t('editBms.deleteBmsButton')}}</base-button>
          </div>
        </div>

      </card>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { voltageEnum, batteryTechEnum } from '@/config/env'
  export default {
    data() {
      return {
        model: {},
        voltageEnum: voltageEnum,
        batteryTechEnum: batteryTechEnum
      }
    },
    mounted() {
      this.model = JSON.parse(localStorage.getItem("selectedBms"))
    },
    methods: {
      async saveBms() {
        console.log("Updating existing bms")
        this.model.received = false;
        console.log(this.model)
        if(this.model.techBatt == 5 || this.model.techBatt == 6) {
          this.model.vNominal = Number(this.model.numCells) * Number(this.model.cellsNominalVoltage)
        } else {
          delete this.model.numCells
          delete this.model.cellsNominalVoltage
        }
        console.log(this.model)
        this.axios.post('https://8jd3b0rjvl.execute-api.eu-central-1.amazonaws.com/default/listBms', this.model).then((response) => {    
          console.log("UPDATED BMS")     
          console.log(response)
          this.$router.push('bms-panel')
        }).catch(error => {
          console.log("ERROR UPDATING BMS")
          //console.log(error)
          console.log(error.response.data)
          this.$router.push('bms-panel')
        });
      },
      async deleteBms() {
        let bmsId = this.model.bms_id
        this.axios.delete(`https://8jd3b0rjvl.execute-api.eu-central-1.amazonaws.com/default/listBms?id=${bmsId}`).then((response) => {    
          console.log("DELETED BMS")     
          console.log(response)
          this.$router.push('bms-panel')
        }).catch(error => {
          console.log("ERROR DELETING BMS")
          //console.log(error)
          console.log(error.response.data)
          this.$router.push('bms-panel')
        });
      },
      back() {
        this.$router.push('bms-panel')
      }
    },
    computed: {
      ...mapState(['isAdmin']),
      isNewUser() {
        this.model.username == ""
      }
    }
  }
</script>
<style>

li.badge{
  margin: 5px;
  background-color: #3792cb;
  
}

span.b-form-tag-content{
  font-size: 1rem ;
  font-weight: 500;
  color: white;
  margin: 10px;
}

button.b-form-tags-button{
  height: 50px;
  background-color: #3792cb;
  background-image: none;
}

</style>
