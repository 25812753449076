<template>
  <div>
    <card>
      <form @submit.prevent>
        <div class="form-row">

          <base-input v-if="isAdmin || isLvl1User" class="col-md-3" placeholder="User">
            <select v-model="selectedUser" id="inputState" class="form-control">
              <option :value="{}" disabled>{{$t('commons.userSelectPlaceholder')}}</option>
              <option
                v-for="(user) in users"
                :value="user"
                :key="user.email"
              >{{user.username}}</option>
            </select>             
          </base-input>

          <base-input class="col-md-2" placeholder="Bms">
            <select v-model="selectedBMS" id="inputState" class="form-control">
              <option value="" disabled>{{$t('commons.bmsSelectPlaceholder')}}</option>
              <option
                v-for="(bms) in getBMSs"
                :value="bms.bms_id"
                :key="bms.bms_id"
              >{{bms.alias || bms.bms_id}}</option>
            </select>
          </base-input>
        </div>
      </form>
    </card>

    <div v-if="isDataReady">
      <div v-if="isDataReady" class="row">
        <div class="col-md-6">
          <card class="padded-card text-center">  
            <h5 class="card-category">{{$t('recap.workingTime')}}</h5>
            <h1 class="card-text">
              <i class="tim-icons icon-watch-time spaced-icon"></i>
              {{formatMinutes(totalForkTime)}}</h1> 
          </card>
        </div>

        <div class="col-md-6">
          <card class="padded-card text-center">  
            <h5 class="card-category">{{$t('recap.cycles')}}</h5>
            <h1 class="card-text">
              <i class="tim-icons icon-refresh-02 spaced-icon"></i>
              {{numCycles || '-'}}</h1> 
          </card>
        </div>

      </div>

      <div class="row">
        <div v-if="isDataReady && items.length > 0" class="col-md-8">
          <!--<card :title="'BMS ' + selectedBMS + ' errors'">-->
          <card>
            <h2 class="card-title">{{currentBmsAlias}} - {{$t('recap.errors')}}</h2>
            <div class="table-responsive">
              <b-table 
                striped 
                hover 
                sticky-header
                id="my-table"
                :fields="errorTableFields"
                :items="items"
                :per-page="perPage"
                :current-page="currentPage">
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </card>
        </div>
        <div v-else-if="loadedErrors" class="col-md-8">
          <card >
            <div>
              <h2 class="card-title">
            <center>{{$t('recap.noErrors')}}</center>
              </h2>
            </div>
          </card>
        </div>
        <div v-if="selectedBMS && isDataReady" class="col-md-4">
          <card>
            <h2 class="card-title">
              <i class="tim-icons icon-notes spaced-icon"></i>
              {{currentBmsAlias}}</h2>
            <b-table hover :items="getSettings" thead-class="d-none"></b-table>
          </card>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapState } from 'vuex'
import Card from '@/components/Cards/Card.vue';
import {InfluxDB, FluxTableMetaData} from '@influxdata/influxdb-client'
import {url, org, batteryTechEnum} from '@/config/env'

export default {
  components: {
      Card
  },
  data() {
    return {
      selectedUser: {},
      perPage: 15,
      currentPage: 1,
      items: [],
      settings: {},
      loaded: false,
      selectedBMS: '',
      numCycles: '',
      totalChgTime: '',
      totalForkTime: '',
      totalTmr: '',
      
      loadedData: false,
      loadedSettings: false,
      loadedErrors: false,
      loadedBMS: '',

      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
    };
  },
  computed: {
    ...mapState([
      'alias',
      'isAdmin',
      'isLvl1User',
      'logged',
      'whiteTheme',
      'calendarColor',
      'users',
      'bmss'
    ]),
    isDataReady() {
      return this.loadedData && this.loadedErrors
    },
    getBMSs () {
      //return this.selectedUser.bmsList || []
      return this.bmss.filter(bms => bms.owner == this.selectedUser.email)
    },
    rows() {
      return this.items.length
    },
    getSettings() {
      let fullBms = this.bmss.find(bms => bms.bms_id == this.selectedBMS)
      return [
        { key: this.$t('recap.serialNumber'), value: fullBms.bms_id },
        { key: this.$t('recap.nominalVoltage'), value: fullBms.vNominal ? fullBms.vNominal + ' V' : '' },
        { key: this.$t('recap.chargeCurve'), value: batteryTechEnum[fullBms.techBatt] ? batteryTechEnum[fullBms.techBatt] : fullBms.techBatt },
        //{ key: 'Charge time', value: this.formatMinutes(this.totalChgTime) },
        { key: this.$t('recap.manufacturer'), value: fullBms.manufacturer },
        { key: this.$t('recap.year'), value: fullBms.yearConst },
        { key: this.$t('recap.type'), value: fullBms.typeBatt },
        { key: this.$t('recap.capacity'), value: fullBms.cNominal ? fullBms.cNominal + ' Ah' : '' }
      ]
    },
    currentBmsAlias() {
      const loadedBms = this.loadedBMS;
      if(loadedBms && this.bmss) {
        var bms = this.bmss.find(bms => bms.bms_id == loadedBms)
        if(bms && bms.alias)
          return bms.alias
      }
      return loadedBms
    },
    errorTableFields() {
      let fields = [
        {
          key: 'Time',
          label: this.$i18n.t('recap.date')},
        {
          key: 'Error',
          label: this.$i18n.t('recap.error')},
      ]
      return fields
    }
  },
  watch: {
    selectedBMS: function(val) {
      this.loadData();
    }
  },
  mounted() {
    if(!this.isAdmin) this.selectedUser = this.users[0]
  },
  methods: {
    getToken() {
      return this.selectedUser.token || '';
    },
    getBucket() {
      return this.selectedUser.bucket || '';
    },
    formatMinutes(minutes) {
      if(!minutes)
        return '-'
      else
        return Math.trunc(minutes/60) + 'h '  + (minutes % 60) + 'm'
    },
    loadData() {      
      let token = this.getToken();
      let bucket = this.getBucket();
      let queryApi = new InfluxDB({url, token}).getQueryApi(org)

      var outerScope = this
      var settings = {}
      var cycleTimes = []
      var partialChargeTimes = []
      this.items = []
      this.currentPage = 1
      this.loadedData = false
      this.loadedSettings = false
      this.loadedErrors = false
      this.loadedBMS = this.selectedBMS
      this.numCycles = ''
      this.totalTmr = ''
      this.totalChgTime = ''
      this.totalForkTime = ''

/*
      const cyclesQuery = `from(bucket: "${bucket}")
                    |> range(start: -10y)
                    |> filter(fn: (r) => r._measurement == "tl" )
                    |> filter(fn: (r) => r.bm == "${this.selectedBMS}" )
                    |> filter(fn: (r) => r._field == "totalTmr" or
                      r._field == "chgTime" )
                    |> top(n:1, columns: ["_value"])`  
*/

      const cyclesQuery = `cyclesData = from(bucket: "${bucket}")
                            |> range(start: -10y)
                            |> filter(fn: (r) => r["_measurement"] == "tl")
                            |> filter(fn: (r) => r["_field"] == "ct" or r["_field"] == "ft")
                            |> filter(fn: (r) => exists r.nu)
                            |> filter(fn: (r) => r["bm"] == "${this.selectedBMS}")
                            |> group(columns: ["bm", "nu"])
                          minChargeTime = cyclesData
                              |> filter(fn: (r) => r["_field"] == "ct")
                              |> min()
                          maxChargeTime = cyclesData
                              |> filter(fn: (r) => r["_field"] == "ct")
                              |> max()
                          minForkTime = cyclesData
                              |> filter(fn: (r) => r["_field"] == "ft")
                              |> min()
                          maxForkTime = cyclesData
                              |> filter(fn: (r) => r["_field"] == "ft")
                              |> max()
                          chargeDurations = join(tables: {minCt: minChargeTime, maxCt: maxChargeTime}, on: ["_start", "_stop", "bm", "nu", "_field", "_measurement"], method: "inner")
                            |> map(fn: (r) => ({ r with chargeDuration: int(v: r._value_maxCt) - int(v: r._value_minCt) }))
                            |> group()
                          totalChargeDuration = chargeDurations
                            |> sum(column: "chargeDuration")
                            |> yield(name: "totalChargeDuration")

                          totalChargesCount = chargeDurations
                            |> count(column: "nu")
                            |> yield(name: "totalChargesCount")

                          forkDurations = join(tables: {minFt: minForkTime, maxFt: maxForkTime}, on: ["_start", "_stop", "bm", "nu", "_field", "_measurement"], method: "inner")
                            |> map(fn: (r) => ({ r with forkDuration: int(v: r._value_maxFt) - int(v: r._value_minFt) }))
                            |> group()
                          totalForkDuration = forkDurations
                            |> sum(column: "forkDuration")
                            |> yield(name: "totalForkDuration")`                  

      const settingsQuery = `from(bucket: "${bucket}") 
                            |> range(start: -10y)
                            |> filter(fn: (r) => r._measurement == "tl")
                            |> filter(fn: (r) => r.bm == "${this.selectedBMS}")
                            |> filter(fn: (r) => 
                              r._field == "vn" or 
                              r._field == "tb" or 
                              r._field == "chgTime" or 
                              r._field == "ma" or 
                              r._field == "yc" or 
                              r._field == "ty" or 
                              r._field == "cn" )
                            |> group(columns: ["_field"])
                            |> top(n:1, columns: ["_time"])
                            |> yield(name: "info")`

      const errorQuery = `from(bucket: "${bucket}") 
                          |> range(start: -10y)
                          |> filter(fn: (r) => r._measurement == "tl" and r.bm == "${this.selectedBMS}" and r._field == "e1")
                          |> filter(fn: (r) => r._value != 0)
                          |> aggregateWindow(every: 1d, fn: distinct, createEmpty: false)
                          |> timeShift(duration: -1d)
                          |> yield(name: "errors")`

      //console.log('Querying influx for global recap.');
      //console.log(cyclesQuery)
      //console.log(settingsQuery)
      //console.log(errorQuery)
      
/*
      queryApi.queryRows(cyclesQuery, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          if(o.numCycle && o._field == 'totalTmr')
            cycleTimes.push(Number(o._value) || 0)
          if(o._field == 'chgTime') {
            partialChargeTimes.push(Number(o._value) || 0)
          }
        },
        error(error) {
          console.log('CYCLES FETCH ERROR')
          console.error(error)
        },
        complete() {
          //console.log('CYCLES FETCH SUCCESS')
          outerScope.totalTmr = cycleTimes.reduce((a,b) => a + b, 0)
          outerScope.totalChgTime = partialChargeTimes.reduce((a,b) => a + b, 0)
          outerScope.numCycles = cycleTimes.length > 0 ? cycleTimes.length - 1 : 0
          outerScope.loadedData = true;
        },
      })
*/
      queryApi.queryRows(cyclesQuery, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          switch (o.result) {
            case 'totalChargeDuration':
              outerScope.totalChgTime = o.chargeDuration       
              break
            case 'totalChargesCount':
              outerScope.numCycles = o.nu       
              break
            case 'totalForkDuration':
              outerScope.totalForkTime = o.forkDuration       
              break
          }
        },
        error(error) {
          console.log('CYCLES FETCH ERROR')
          console.error(error)
        },
        complete() {
          //console.log('CYCLES FETCH SUCCESS')
          outerScope.loadedData = true;
        },
      })

/*
      queryApi.queryRows(settingsQuery, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          switch (o._field) {
            case 'vn':
              settings.vNominal = o._value
              break
            case 'tb':
              settings.tech = o._value
              break
            case 'chgTime':
              settings.chgTime = o._value
              break
            case 'ma':
              settings.man = o._value
              break
            case 'yc':
              settings.yearConst = o._value
              break
            case 'ty':
              settings.batt_type = o._value
              break
            case 'cn':
              settings.cNominal = o._value
              break
          }
        },
        error(error) {
          console.log('SETTINGS FETCH ERROR')
          console.error(error)
        },
        complete() {
          //console.log('SETTINGS FETCH SUCCESS')
          outerScope.settings = settings
          outerScope.loadedSettings = true;
        },
      })
*/
      queryApi.queryRows(errorQuery, {
        next(row, tableMeta) {
          const o = tableMeta.toObject(row)
          //outerScope.items.push(JSON.parse(o.jsonStr))
          var datum = {}
          var date = new Date(o._time)
          datum.Time = date.toLocaleDateString()
          var errorText = o._value
          if(!isNaN(parseInt(o._value))) {
            var errCode = parseInt(o._value)
            var hexValue = '0x'+errCode.toString(16)
            errCode = errCode >> 7
            if(errCode == 1) {
              hexValue += " - Refill"
            }
            errorText = hexValue
          }
          datum.Error = errorText
          //datum.BMS = o.bms
          //datum.CB = o.origin
          outerScope.items.push(datum)
        },
        error(error) {
          console.log('ERRORS FETCH ERROR')
          console.error(error)
        },
        complete() {
          //console.log('ERRORS FETCH SUCCESS')
          outerScope.loadedErrors = true;
        },
      })
      
    }
  }
};
</script>
<style src="@/assets/css/input-bar.css"/>
<style>

.padded-card {
  padding: 25px 0px 35px 0px;
}

.spaced-icon {
  margin-right: 15px;
}

</style>
