<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <h5 slot="header" class="title">{{$t('editUser.cardTitle')}}</h5>
        <div class="row">
          
          <div v-if=isNewUser class="col-md-5 pr-md-1">
            <base-input :label="$t('editUser.company')"
                      :placeholder="$t('editUser.company')"
                      v-model="model.company"
                      >
            </base-input>
          </div>
          <div v-else class="col-md-3 pr-md-1">
            <base-input :label="$t('editUser.company')"
                        :placeholder="$t('editUser.company')"
                        v-model="model.company"
                        >
              </base-input>
          </div>
          <div class="col-md-3 px-md-1">
            <base-input :label="$t('editUser.username')"
                      :placeholder="$t('editUser.username')"
                      v-model="model.username"
                      >
            </base-input>
          </div>
          <div v-if=isNewUser class="col-md-4 pl-md-1">
            <base-input :disabled="!isNewUser" :label="$t('editUser.email')"
                      type="email"
                      v-model="model.email"
                      :placeholder="$t('editUser.emailPlaceholder')">
            </base-input>
          </div>
          <div v-else class="col-md-3 pl-md-1">
            <base-input :disabled="!isNewUser" :label="$t('editUser.email')"
                      type="email"
                      v-model="model.email"
                      :placeholder="$t('editUser.emailPlaceholder')">
            </base-input>
          </div>
          <div v-if=!isNewUser class="col-md-3 pl-md-1">
            <base-input disabled :label="$t('editUser.creator')"
                      type="email"
                      v-model="model.creator">
            </base-input>
          </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
          <div class="col-md-12">
            <base-input :label="$t('editUser.address')"
                      v-model="model.address"
                      :placeholder="$t('editUser.addressPlaceholder')">
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input :label="$t('editUser.city')"
                      v-model="model.city"
                      :placeholder="$t('editUser.city')">
            </base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input :label="$t('editUser.country')"
                      v-model="model.country"
                      :placeholder="$t('editUser.country')">
            </base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input :label="$t('editUser.postalCode')"
                      v-model="model.zipCode"
                      :placeholder="$t('editUser.postalCode')">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-2">
            <base-input :label="$t('editUser.bucket')"
                      v-model="model.bucket"
                      :placeholder="$t('editUser.bucketPlaceholder')">
            </base-input>
          </div>
          <div class="col-md-8">
            <base-input :label="$t('editUser.token')"
                      v-model="model.token"
                      :placeholder="$t('editUser.tokenPlaceholder')">
            </base-input>
          </div>

          
        </div>

        <div class="row">

          <div class="col-md-12" style="margin-bottom: 10px;">
            <div v-if=isAdmin class="form-check pull-left" name="admin">
              <label for="chb_isAdmin" class="form-check-label">
                  <input
                      id="chb_isAdmin" 
                      v-model="model.admin"
                      type="checkbox" class="form-check-input">
                  <span class="form-check-sign"></span> 
                  Admin 
              </label>
            </div>

            <div v-if=isAdmin class="form-check pull-left" name="canCreate">
              <label for="chb_canCreate" class="form-check-label">
                  <input
                      id="chb_canCreate" 
                      v-model="model.canCreateUsers"
                      type="checkbox" class="form-check-input">
                  <span class="form-check-sign"></span> 
                  {{$t('editUser.canCreateUsers')}}
              </label>
            </div>

            <div class="form-check pull-left" name="canConfig">
              <label for="chb_canConfig" class="form-check-label">
                  <input
                      id="chb_canConfig" 
                      v-model="model.canConfigBms"
                      type="checkbox" class="form-check-input">
                  <span class="form-check-sign"></span> 
                  {{$t('editUser.canEditBms')}}
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="tags">{{$t('editUser.associatedBms')}}</label>
            <b-form-tags
              id="tags-"
              v-model="currentUserBmsList"
              add-on-change
              no-outer-focus
            >
              <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2" style="margin: 0px !important;">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :disabled="disabled"
                      variant="info"
                    >{{ tag }}</b-form-tag>
                  </li>
                </ul>

                <base-input class="list-inline d-inline-block col-md-2" :placeholder="$t('commons.bmsSelectPlaceholder')" style="margin-bottom: 0px;">
                  <select v-bind="inputAttrs" v-on="inputHandlers" id="inputState" class="form-control">
                    <option value="" disabled selected hidden>{{$t('commons.bmsSelectPlaceholder')}}</option>
                    <option
                      v-for="(bms) in availableBms"
                      :value="bms"
                      :key="bms"
                    >{{bms}}</option>
                  </select>             
                </base-input>

              </template>
            </b-form-tags>
            
          </div>
        </div>
        <div slot="footer" class="button-row">
          <div class="button-container">
            <base-button slot="footer" type="primary-nogradient" @click="saveUser()" fill>{{$t('editUser.saveButton')}}</base-button>
          </div>
          <div class="button-container">
            <base-button slot="footer" type="primary-nogradient" @click="back()" fill>{{$t('editUser.backButton')}}</base-button>
          </div>
          <div class="button-container">
            <base-button slot="footer" type="danger-nogradient" @click="deleteUser()" fill>{{$t('editUser.deleteUserButton')}}</base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        model: {},
        isNewUser: false,
      }
    },
    mounted() {
      // Temporary var user is necessary in case bmsList is missing
      // When this.model is assigned, vue starts watching all it's properties
      // If bmsList is missign and assigned after this.model is assigned, it is not watched
      // This prevent the computed properties on which the select component is based to work properly
      var user = JSON.parse(localStorage.getItem("selectedUser"))
      this.isNewUser = (!user || Object.keys(user).length === 0) 
      if(this.isNewUser)
        user.creator = this.loggedUserEmail
      if(!user.bmsList) user.bmsList = []
      this.model = user
    },
    computed: {
      ...mapState([
        'bmss',
        'loggedUser',
        'loggedUserEmail',
        'isAdmin'
      ]),
      currentUserBmsList: {
        get: function() {
          return 'bmsList' in this.model ? this.model.bmsList : []
        },
        set: function(newValue) {
          this.model.bmsList = newValue
        }      
      },
      availableBms() {
        let bmsList = ('bmsList' in this.model) ? this.model.bmsList : []
        return this.bmss
          .filter(bms => !bms.hasOwnProperty('owner') || bms.owner == this.model.email)         
          .map(bms => bms.bms_id)
          .filter(bms => !bmsList.includes(bms))
      }
    },
    methods: {
      async saveUser() {
        // TODO put in data and read localStorage on mounted()
        let selectedUser = localStorage.getItem("selectedUser")

        if(!selectedUser || selectedUser == '{}') {
          console.log("Creating new user")    
          console.log(this.model)
          this.axios.post('https://0uokh9agib.execute-api.eu-central-1.amazonaws.com/default/createUser-dev', this.model).then((response) => {    
            console.log("CREATED USER")     
            console.log(response)
            this.$router.push('users-panel')
          }).catch(error => {
            console.log("ERROR CREATING USER")
            //console.log(error)
            console.log(error.response.data)
            this.$router.push('users-panel')
          });     
        } else {
          console.log("Updating existing user")
          console.log(this.model)
          var payload = {
            user: this.model,
            issuer: this.loggedUser
          }
          this.axios.post('https://5e80bb0mtk.execute-api.eu-central-1.amazonaws.com/default/listBmsUsers', payload).then((response) => {    
            console.log("UPDATED USER")     
            console.log(response)
            this.$router.push('users-panel')
          }).catch(error => {
            console.log("ERROR UPDATING USER")
            //console.log(error)
            console.log(error.response.data)
            this.$router.push('users-panel')
          });
        }
      },
      async deleteUser() {
        let userEmail = this.model.email
        this.axios.delete(`https://5e80bb0mtk.execute-api.eu-central-1.amazonaws.com/default/listBmsUsers?id=${userEmail}`).then((response) => {    
          console.log("DELETED USER")     
          console.log(response)
          this.$router.push('users-panel')
        }).catch(error => {
          console.log("ERROR DELETING USER")
          //console.log(error)
          console.log(error.response.data)
          this.$router.push('users-panel')
        });
      },
      back() {
        this.$router.push('users-panel')
      }
    }
  }
</script>
<style>

li.badge, span.badge{
  margin: 5px;
  background-color: #3792cb;
}

span.b-form-tag-content{
  font-size: 1rem ;
  font-weight: 500;
  color: white;
  margin: 10px;
}

button.b-form-tags-button{
  height: 50px;
  background-color: #3792cb;
  background-image: none;
}

div.list-inline {
  margin-left: 5px;
  padding: 0;
}

.form-check {
  margin-right: 15px;
}

</style>
