<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <h5 slot="header" class="title">{{$t('editCustomer.cardTitle')}}</h5>
        <div v-if="isAdmin" class="row">
          <div class="col-md-3">
            <base-input :label="$t('editCustomer.user')" :placeholder="$t('commons.bmsSelectPlaceholder')">
                  <select v-model="selectedUser" @change="onSelectedUserChange($event)" id="inputState" class="form-control">
                    <option :value={} disabled selected hidden>{{$t('commons.userSelectPlaceholder')}}</option>
                    <option
                      v-for="(user) in users"
                      :value="user"
                      :key="user.email"
                    >{{user.username}}</option>
                  </select>             
                </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 pr-md-1">
            <base-input :label="$t('editCustomer.name')"
                      :placeholder="$t('editCustomer.name')"
                      v-model="model.name"
                      >
            </base-input>
          </div>
          <div class="col-md-5 px-md-1">
            <base-input :label="$t('editCustomer.company')"
                      :placeholder="$t('editCustomer.company')"
                      v-model="model.company"
                      >
            </base-input>
          </div>     
          <div class="col-md-4 pl-md-1">
            <base-input :label="$t('editCustomer.email')"
                      type="email"
                      v-model="model.email"
                      :placeholder="$t('editCustomer.emailPlaceholder')">
            </base-input>
          </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
          <div class="col-md-12">
            <base-input :label="$t('editCustomer.address')"
                      v-model="model.address"
                      :placeholder="$t('editCustomer.addressPlaceholder')">
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 pr-md-1">
            <base-input :label="$t('editCustomer.city')"
                      v-model="model.city"
                      :placeholder="$t('editCustomer.city')">
            </base-input>
          </div>
          <div class="col-md-4 px-md-1">
            <base-input :label="$t('editCustomer.country')"
                      v-model="model.country"
                      :placeholder="$t('editCustomer.country')">
            </base-input>
          </div>
          <div class="col-md-4 pl-md-1">
            <base-input :label="$t('editCustomer.postalCode')"
                      v-model="model.zipCode"
                      :placeholder="$t('editCustomer.postalCode')">
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label for="tags">{{$t('editCustomer.rentedBms')}}</label>
            <b-form-tags
              id="tags-"
              v-model="currentRenterBmsList"
              add-on-change
              no-outer-focus
            >
              <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2" style="margin: 0px !important;">
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :disabled="disabled"
                      variant="info"
                    >{{ tag }}</b-form-tag>
                  </li>
                </ul>

                <base-input class="list-inline d-inline-block col-md-2" :placeholder="$t('commons.bmsSelectPlaceholder')" style="margin-bottom: 0px;">
                  <select v-bind="inputAttrs" v-on="inputHandlers" id="inputState" class="form-control">
                    <option value="" disabled selected hidden>{{$t('commons.bmsSelectPlaceholder')}}</option>
                    <option
                      v-for="(bms) in availableBms"
                      :value="bms"
                      :key="bms"
                    >{{bms}}</option>
                  </select>             
                </base-input>

              </template>
            </b-form-tags>
            
          </div>
        </div>
        <div slot="footer" class="button-row">
          <div class="button-container">
            <base-button slot="footer" type="primary-nogradient" @click="saveRenter()" fill>{{$t('editCustomer.saveButton')}}</base-button>
          </div>
          <div class="button-container">
            <base-button slot="footer" type="danger-nogradient" @click="cancel()" fill>{{$t('editCustomer.backButton')}}</base-button>
          </div>
          <div class="button-container">
            <base-button slot="footer" type="danger-nogradient" @click="deleteRenter()" fill>{{$t('editCustomer.deleteCustomerButton')}}</base-button>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Auth } from 'aws-amplify';
import { mapState } from 'vuex'

  export default {
    data() {
      return {
        model: {},
        selectedUser: {}
      }
    },
    mounted() {
      // Temporary renter user is necessary in case bmsList is missing
      // When this.model is assigned, vue starts watching all it's properties
      // If bmsList is missign and assigned after this.model is assigned, it is not watched
      // This prevent the computed properties on which the select component is based to work properly
      var renter = JSON.parse(localStorage.getItem("selectedRenter"))
      if(!renter.bmsList) renter.bmsList = []
      this.model = renter
      if(!this.isAdmin) {
        this.selectedUser = this.loggedUser
      } 
      else if(this.model.creator) {
        this.selectedUser = this.users.find(u => u.email == this.model.creator)
      } 
    },
    computed: {
      ...mapState([  
        'users',
        'bmss',
        'loggedUser',
        'loggedUserEmail',
        'isAdmin'
      ]),
      currentRenterBmsList: {
        get: function() {
          return 'bmsList' in this.model ? this.model.bmsList : []
        },
        set: function(newValue) {
          this.model.bmsList = newValue
        }
        
      },
      availableBms() {
        let bmsList = ('bmsList' in this.model) ? this.model.bmsList : []
        return this.bmss
          .filter(bms => bms.owner == this.selectedUser.email)
          .map(bms => bms.bms_id)
          .filter(bms => !bmsList.includes(bms))
      }
    },
    methods: {
      async saveRenter() {
        console.log("Updating renter")
        this.model.creator = this.selectedUser.email
        this.model.creatorName = this.selectedUser.username
        console.log(this.model)
        this.axios.post('https://uvcn18cyw9.execute-api.eu-central-1.amazonaws.com/default/listRenters', this.model).then((response) => {    
          console.log("UPDATED RENTER")     
          console.log(response)
          this.$router.push('customers-panel')
        }).catch(error => {
          console.log("ERROR UPDATING RENTER")
          //console.log(error)
          console.log(error.response.data)
          this.$router.push('customers-panel')
        });

      },
      async deleteRenter() {
        this.axios.delete(`https://uvcn18cyw9.execute-api.eu-central-1.amazonaws.com/default/listRenters?renterId=${this.model.renterId}`).then((response) => {    
          console.log("DELETED RENTER")     
          console.log(response)
          this.$router.push('customers-panel')
        }).catch(error => {
          console.log("ERROR DELETING RENTER")
          //console.log(error)
          console.log(error.response.data)
          this.$router.push('customers-panel')
        });

      },
      onSelectedUserChange(event) {
        console.log("EVENT")
        console.log(event)
        this.model.bmsList = []
      },
      cancel() {
        this.$router.push('customers-panel')
      }
    }
  }
</script>
<style>

li.badge, span.badge{
  margin: 5px;
  background-color: #3792cb;
  
}


span.b-form-tag-content{
  font-size: 1rem ;
  font-weight: 500;
  color: white;
  margin: 10px;
}

button.b-form-tags-button{
  height: 50px;
  background-color: #3792cb;
  background-image: none;
}

div.list-inline {
  margin-left: 5px;
  padding: 0;
}

</style>
